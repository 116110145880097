import React from "react";
import Preguntas from "../Components/Preguntas/Preguntas";
import Header from "../Components/Layout/Header/Header";
import Footer from "../Components/Layout/Footer/Footer";

function Faq() {
  return (
    <div>
      <Header />
      <Preguntas />
      <Footer />
    </div>
  );
}

export default Faq;
