/* eslint-disable */
import React, { useState, useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Modal, Button, Col, Row, Container } from "react-bootstrap";
import dayjs from "dayjs";
import odontograma from "../../Imagenes/odontograma.jpeg";
import "jspdf-autotable";
import "./Expediente.css"

function Expediente(props) {
  const { user1, isAuth } = useContext(AuthContext);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  const [noteonmodal, setNoteonmodal] = useState("");
  let fecha = props.user.nacimiento;
  let hoy = dayjs().format("DD/MM/YYYY");

  function imprime() {
    printElement(document.getElementById("printThis"));
  }

  function printElement(elem) {
    var domClone = elem.cloneNode(true);
    var $printSection = document.getElementById("printSection");
    if (!$printSection) {
      var $printSection = document.createElement("div");
      $printSection.id = "printSection";
      document.body.appendChild($printSection);
    }
    $printSection.innerHTML = "";
    $printSection.appendChild(domClone);
    window.print();
    document.body.removeChild($printSection);
  }

  return (
    <>
      {isAuth ? (
        user1.role === "doctor" || user1.role == "admin" || user1.role === "secre" ? (
          <>
            <Button
              variant="success"
              onClick={() => (handleShow(), setNoteonmodal(props.note))}
            >
              <i className="far fa-solid fa-file"></i>
            </Button>
            <Modal size="xl" show={show} onHide={handleClose} class="wrap">
              <Modal.Header closeButton>
                <Row>
                  <Col>
                    <Modal.Title>Expediente</Modal.Title>
                  </Col>
                  <Col>
                    <Button onClick={() => imprime()} id="btnPrint" variant="outline-primary" class="btn btn-default"><i class="fas fa-print"></i></Button>
                  </Col>
                </Row>
              </Modal.Header>
              <Modal.Body className="letraExpediente">

                <div id="printThis" className="letra">

                  <div className="fila fotoCentrada">
                    <h2>{props.user.apellido} {props.user.nombre}</h2>
                  </div>

                  <div className="fila">
                    {!props.user.email ? <span className="borderGde">Email:</span> : <span className="borderGde">Email: {props.user.email}</span>}
                    {!props.user.nacimiento ? <span className="borderGde">Fecha de nacimiento:</span> : <span className="borderGde">Fecha de nacimiento: {dayjs(fecha).format("DD-MM-YYYY")}</span>}
                  </div>

                  <div className="fila">
                    {!props.user.domicilio ? <span className="borderGde">Domicilio:</span> : <span className="borderGde">Domicilio: {props.user.domicilio}</span>}
                    {!props.user.dni ? <span className="borderGde">N° de documento:</span> : <span className="borderGde">N° de documento: {props.user.dni}</span>}
                    {!props.user.recomendadoPor ? <span className="borderGde">Recomendado por:</span> : <span className="borderGde">Recomendado por: {props.user.recomendadoPor}</span>}
                  </div>

                  <div className="fila">
                    {!props.user.localidad ? <span className="borderGde">Localidad:</span> : <span className="borderGde">Localidad: {props.user.localidad}</span>}
                    {!props.user.obraSocial ? <span className="borderGde">Cobertura:</span> : <span className="borderGde">Cobertura: {props.user.obraSocial}</span>}
                    {!props.user.medicoCabecera ? <span className="borderGde">Médico de cabecera:</span> : <span className="borderGde">Médico de cabecera: {props.user.medicoCabecera}</span>}
                  </div>

                  <div className="fila">
                    {!props.user.telefono ? <span className="borderGde">Teléfono:</span> : <span className="borderGde">Teléfono: {props.user.telefono}</span>}
                    {!props.user.numeroObraSocial ? <span className="borderGde">N° de Afiliado:</span> : <span className="borderGde">N° de Afiliado: {props.user.numeroObraSocial}</span>}
                    {!props.user.telMedicoCabecera ? <span className="borderGde">Teléfono:</span> : <span className="borderGde">Teléfono: {props.user.telMedicoCabecera}</span>}
                  </div>

                  <div className="fila">
                    {!props.user.celular ? <span className="borderGde">Celular:</span> : <span className="borderGde">Celular: {props.user.celular}</span>}
                    {!props.user.plan ? <span className="borderGde">Plan:</span> : <span className="borderGde">Plan: {props.user.plan}</span>}
                  </div>

                  <div className="fila altura fotoCentrada margen">
                    <img src={odontograma} />
                  </div>

                  <div className="fila margen fotoCentrada">
                    {!props.user.motivoConsulta ? <span className="borderGdeVacio">Motivo de su consulta:</span> : <span className="borderGde">Motivo de su consulta: {props.user.motivoConsulta}</span>}
                  </div>

                  <div className="margen filaAbajo">
                    <span >Padeció ó padece alguna de estas patoligias?</span>
                    <div style={{ minWidth: '300px' }}></div>
                  </div>

                  <div className="filaAbajo">
                    {!props.user.diabetes ? <span className="borderGde">Diabetes:</span> : <span className="borderGde">Diabetes: {props.user.diabetes ? "Sí" : "No"}</span>}
                    {!props.user.embarazada ? <span className="borderGde">Esta embarazada:</span> : <span className="borderGde">Esta embarazada: {props.user.embarazada ? "Sí" : "No"}</span>}
                  </div>

                  <div className="filaAbajo">
                    {!props.user.cardiacas ? <span className="borderGde">Enfermedades Cardíacas:</span> : <span className="borderGde">Enfermedades Cardíacas: {props.user.cardiacas ? "Sí" : "No"}</span>}
                    {!props.user.mesEmbarazo ? <span className="borderGde">En que mes?:</span> : <span className="borderGde">En que mes?: {props.user.mesEmbarazo}</span>}
                    {/* {!props.user.medicamento ? <span className="borderGde">Toma algún medicamento:</span> : <span className="borderGde">Toma algún medicamento: {props.user.medicamento ? "Sí" : "No"}</span>} */}
                  </div>

                  <div className="filaAbajo">
                    {!props.user.hepaticas ? <span className="borderGde">Enfermedades Hepáticas:</span> : <span className="borderGde">Enfermedades Hepáticas: {props.user.hepaticas ? "Sí" : "No"}</span>}
                    {!props.user.alergias ? <span className="borderGde">Alergías:</span> : <span className="borderChico">Alergías: {props.user.alergias ? "Sí" : "No"}</span>}
                  </div>

                  <div className="filaAbajo">
                    {!props.user.renales ? <span className="borderGde">Enfermedades Renales:</span> : <span className="borderGde">Enfermedades Renales: {props.user.renales ? "Sí" : "No"}</span>}
                    {!props.user.cualesEnfermedades ? <span className="borderGde">Cuál:</span> : <span className="borderChico">Cuál: {props.user.cualesEnfermedades}</span>}
                  </div>

                  <div className="filaAbajo">
                    {!props.user.medicamentoMal ? <span className="borderGde">Algún medicamento le hace mal:</span> : <span className="borderChico">Algún medicamneto le hace mal: {props.user.medicamentoMal ? "Sí" : "No"}</span>}
                    {!props.user.fuma ? <span className="borderGde">Fuma:</span> : <span className="borderGde">Fuma: {props.user.fuma ? "Sí" : "No"}</span>}
                  </div>

                  <div className="filaAbajo">
                    {!props.user.anesteciaLocal ? <span className="borderGde">Recibió anestesia local bucal:</span> : <span className="borderGde">Recibió anestesia local bucal: {props.user.anesteciaLocal ? "Sí" : "No"}</span>}
                    {!props.user.cantidadCigarrillos ? <span className="borderGde">Cuantos cigarrilos por dia:</span> : <span className="borderGde">Cuantos cigarrilos por dia: {props.user.cantidadCigarrillos}</span>}
                  </div>

                  <div className="margen filaAbajo">
                    <span style={{ maxWidth: '200px' }}>Solo para niños</span>
                    <div style={{ maxWidth: '230px' }}><span>Historia de dieta para ser completada con el profesional</span></div>
                  </div>

                  <div className="filaAbajo">
                    {!props.user.nacioTermino ? <span className="borderGde">Nació en termino?:</span> : <span className="borderChico">Nació en termino?: {props.user.nacioTermino ? "Sí" : "No"}</span>}
                    {!props.user.desayuno ? <span className="borderGde">Desayuno:</span> : <span className="borderGde">Desayuno: {props.user.desayuno}</span>}
                    {/* {!props.user.otras ? <span className="borderChico">Otras:</span> : <span className="borderChico">Otras: {props.user.otras ? "Sí" : "No"}</span>} */}
                    {/* {!props.user.cualesMedicamentos ? <span className="borderChico">Cuál:</span> : <span className="borderChico">Cuál: {props.user.cualesMedicamentos}</span>} */}
                  </div>

                  <div className="filaAbajo">
                    {!props.user.nacioPeso ? <span className="borderGde">Con que peso nació:</span> : <span className="borderGde">Con que peso nació: {props.user.nacioPeso}</span>}
                    {!props.user.cepillasDesayuno ? <span className="borderGde">Cepillado:</span> : <span className="borderGde">Cepillado: {props.user.cepillasDesayuno ? "Sí" : "No"}</span>}
                  </div>

                  <div className="filaAbajo">
                    {!props.user.amamanto ? <span className="borderGde">Se amamantó?:</span> : <span className="borderChico">Se amamantó?: {props.user.amamanto ? "Sí" : "No"}</span>}
                    {!props.user.mediaMañana ? <span className="borderGde">Media-mañana:</span> : <span className="borderGde">Media-mañana: {props.user.mediaMañana}</span>}
                  </div>

                  <div className="filaAbajo">
                    {!props.user.mamadera ? <span className="borderGde">Tomó mamadera?:</span> : <span className="borderChico">Tomó mamadera?: {props.user.mamadera ? "Sí" : "No"}</span>}
                    {!props.user.almuerzoComida ? <span className="borderGde">Almuerzo:</span> : <span className="borderGde">Almuerzo: {props.user.almuerzoComida}</span>}
                  </div>

                  <div className="filaAbajo">
                    {!props.user.mamaderaAzucar ? <span className="borderGde">Con azúcar:</span> : <span className="borderChico">Con azúcar: {props.user.mamaderaAzucar ? "Sí" : "No"}</span>}
                    {!props.user.merienda ? <span className="borderGde">Merienda:</span> : <span className="borderGde">Merienda: {props.user.merienda}</span>}
                  </div>

                  <div className="filaAbajo">
                    {!props.user.edadCepillado ? <span className="borderGde">Edad de inicio de cepillado:</span> : <span className="borderEnorme">A que edad comenzaron a cepillarce los dientes: {props.user.edadCepillado}</span>}
                    {!props.user.cenaComida ? <span className="borderGde">Cena:</span> : <span className="borderGde">Cena: {props.user.cenaComida}</span>}
                  </div>


                  <div className="filaAbajo">
                    <div style={{ minWidth: '300px' }}></div>
                    {!props.user.cepillasCena ? <span className="borderGde">Cepillado:</span> : <span className="borderGde">Cepillado: {props.user.cepillasCena ? "Sí" : "No"}</span>}
                  </div>

                  <div className="filaAbajo">
                    <div style={{ minWidth: '300px' }}></div>
                    {!props.user.horaDormir ? <span className="borderGde">Hora de ir a dormir:</span> : <span className="borderGde">Hora de ir a dormir: {props.user.horaDormir}</span>}
                  </div>

                  <div className="fila margen">
                    <span>Por la presente autorizo a las Dras. Schmunis, Guz y/o su equipo a realizar los tratamientos que fueren necesarios. Comprendo que un resultado perfecto no puede garantizarse. Se me han explicado los riesgos y complicaciones que puedan existir inherentes al tratamiento. Comprendo que la concurrencia regular a los turnos, los controles periódicos, el buen uso de la aparatoloía si la hubiere y la correcta higiene ayudan al exito del tratamiento. Así mismo me comprometo a pagar los honorarios cuando fueran acordados. Plan de tratamiento:</span>
                  </div>

                  <br />

                  <div className="fila">
                    {<span>Fecha: {hoy}</span>}
                    {<span>Firma:_______________________</span>}
                  </div>

                </div>

              </Modal.Body>
            </Modal>
          </>
        ) : undefined
      ) : undefined
      }
    </>
  );
}

export default Expediente;
