/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Dropdown, DropdownButton, Form, Button, Modal, Col, } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import "../../index.css";
import "../Apointment/Apointment.css";
import dayjs from "dayjs";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
registerLocale("es", es);

function DeleteAllDatesByDoctor() {
    const { user1, isAuth } = useContext(AuthContext);
    const DOCGET = `${process.env.REACT_APP_API}doctors/${user1.id}`;
    const [doctors, setDoctors] = useState([]);
    const [date, setDate] = useState(null);
    const [doctor, setDoctor] = useState(user1.id);
    const [doctorName, setDoctorName] = useState("Escoge");
    const [doctorLname, setDoctorLname] = useState("Doctor");
    const [startDate, setStartDate] = useState(null);
    const titleedbot = `${doctorName} ${doctorLname}`;
    const SCHUDULEPOST = `${process.env.REACT_APP_API}deleteallday/${user1.id}`;
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        axios
            .get(DOCGET, {
                headers: {
                    Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
            })
            .then((data) => setDoctors(data.data))
            .catch((err) => console.log(err));
    }, []);
    
    const diaSeleccionado = (selectedDay) => {
        setStartDate(selectedDay);
        setDate(`${dayjs(selectedDay).format("YYYY-MM-DD")}`);
    };

    const saveDate = () => {
        if (
            doctor === user1.id ||
            date === null
        ) {
            return Swal.fire({
                allowEscapeKey: true,
                icon: "info",
                title: "Informacion incompleta",
                text: "Escoge todos los campos",
                confirmButtonText: `Ok`,
                timerProgressBar: true,
            });
        }
        Swal.fire({
            title: `Los turnos del Dr. ${doctorName} ${doctorLname}, del dia ${date} seran canceladas`,
            text: "Es correcta la informacion ?",
            icon: "question",
            showCancelButton: true,
            reverseButtons: true,
            allowEscapeKey: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Cancelar día",
            cancelButtonText: "Volver",
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`${SCHUDULEPOST}/${doctor}`, { date: date }, {
                    headers: {
                        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                    },
                })
                    .then(() => {
                        Swal.fire({
                            allowEscapeKey: true,
                            icon: "success",
                            title: "Listo! turnos cancelados",
                            confirmButtonText: `Ok`,
                            timer: 1000,
                            timerProgressBar: true,
                        }).then(() => {
                            window.location.reload();
                        });
                    })
                    .catch((error) => {
                        console.log("error")
                        let message = error.response.data.message;
                        Swal.fire({
                            allowEscapeKey: true,
                            icon: "error",
                            title: "Oops...",
                            text: "Lo sentimos esta acción no se pudo completar " + message,
                        });
                        console.log(error);
                    });
            }
        });
    };

    return (
        <>
            {isAuth ? (
                user1.role === "admin" ? (
                    <div>
                        <Button
                            style={{ "maxHeight": "35px" }}
                            variant="danger"
                            onClick={handleShow}
                        >
                            Cancelar dia
                        </Button>
                        <Modal show={show} size="sm" onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Cancelar el dia</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <DropdownButton variant="outline-info" title={titleedbot}>
                                                {doctors.map((user, i) => (
                                                    <Dropdown.Item
                                                        onClick={() => {
                                                            setDoctor(user._id);
                                                            setDoctorName(user.nombre);
                                                            setDoctorLname(user.apellido);
                                                            setDate(null);
                                                            setStartDate(null);
                                                        }}
                                                        key={i}
                                                    >
                                                        <h4 className="alineacion">
                                                            {user.nombre} {user.apellido}
                                                        </h4>
                                                    </Dropdown.Item>
                                                ))}
                                            </DropdownButton>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group>
                                            <DatePicker
                                                calendarStartDay={0}
                                                locale="es"
                                                todayButton="Hoy"
                                                includeDateIntervals={[{ start: new Date(), end: new Date(dayjs().add(90, 'day')) },]}
                                                className="picker"
                                                placeholderText="Selecciona la Fecha"
                                                selected={startDate}
                                                dateFormat="dd/MM/yyyy"
                                                onChange={(date) => diaSeleccionado(date)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    type="submit"
                                    onClick={() => {
                                        saveDate();
                                    }}
                                    className="btn btn-info"
                                >
                                    Aplicar
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                ) : undefined
            ) : undefined}
        </>
    );
}

export default DeleteAllDatesByDoctor;
