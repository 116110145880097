import React, { useState } from "react";
import { Container, Button, Form, Col, Nav, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Header from "../Layout/Header/Header";
import Footer from "../Layout/Footer/Footer";
import "../../index.css";

const Contact = () => {
  const [name, setName] = useState("");
  const [note, setNote] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const history = useHistory();

  const handleForm = async (event) => {
    event.preventDefault();
    const jsonSend = {
      name,
      email,
      note,
      tel,
    };

    const POST_CONTACT = `${process.env.REACT_APP_API}contact/`;

    try {
      await axios.post(POST_CONTACT, jsonSend).then(() => {
        setName("");
        setEmail("");
        setTel("");
        setNote("");
        Swal.fire({
          icon: "success",
          title: "Listo, se enviaron tus datos",
          text: "Te contactaremos en breve",
          timer: 3000,
          timerProgressBar: true,
          allowEscapeKey: true,
        }).then(history.push("/"));
      });
    } catch (error) {
      let message = error.datas;
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Algo salio mal",
        text: message,
        allowEscapeKey: true,
      });
    }
  };

  return (
    <>
      <Header />
      {/* Full Screen Search Start */}
      <div className="modal fade" id="searchModal" tabIndex={-1}>
        <div className="modal-dialog modal-fullscreen">
          <div
            className="modal-content"
            style={{ background: "rgba(9, 30, 62, .7)" }}
          >
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn bg-white btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body d-flex align-items-center justify-content-center">
              <div className="input-group" style={{ maxWidth: 600 }}>
                <input
                  type="text"
                  className="form-control bg-transparent border-primary p-3"
                  placeholder="Type search keyword"
                />
                <button className="btn btn-primary px-4">
                  <i className="bi bi-search" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Full Screen Search End */}
      {/* Hero Start */}
      <div className="container-fluid bg-primary py-5 hero-header mb-5">
        <div className="row py-3">
          <div className="col-12 text-center">
            <h1 className="display-3 text-white animated zoomIn">Contactanos</h1>
            <a href="/" className="h4 text-white">
              Home
            </a>
            <i className="far fa-circle text-white px-2" />
            <a href="/contact" className="h4 text-white">
              Contacto
            </a>
          </div>
        </div>
      </div>
      {/* Hero End */}
      {/* Contact Start */}
      <div className="container-fluid py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-xl-4 col-lg-6 wow slideInUp" data-wow-delay="0.1s">
              <div className="bg-light rounded h-100 p-5">
                <div className="section-title">
                  <h5 className="position-relative d-inline-block text-primary text-uppercase">
                    Contactanos
                  </h5>
                  <h1 className="display-6 mb-4">Llamanos con confianza</h1>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <i className="bi bi-geo-alt fs-1 text-primary me-3" />
                  <div className="text-start">
                    <h5 className="mb-0">Nuestro consultorio</h5>
                    <span>Güemes 4139, CABA</span>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <i className="bi bi-envelope-open fs-1 text-primary me-3" />
                  <div className="text-start">
                    <h5 className="mb-0">Email</h5>
                    <span>info@consultoriosodin.com.ar</span>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <i className="bi bi-phone-vibrate fs-1 text-primary me-3" />
                  <div className="text-start">
                    <h5 className="mb-0">Llamanos</h5>
                    <span>
                      <i className="fa fa-phone-alt me-2" />
                      5491125816420 &nbsp;
                      <i className="fab fa-whatsapp"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 wow slideInUp" data-wow-delay="0.3s">
              <form onSubmit={handleForm}>
                <div className="row g-3">
                  <div className="col-12">
                    <input
                      type="text"
                      onChange={(e) => setName(e.target.value)}
                      className="form-control border-0 bg-light px-4"
                      placeholder="Cual es tu nombre?"
                      style={{ height: 55 }}
                    />
                  </div>
                  <div className="col-12">
                    <input
                      type="email"
                      onChange={(e) => setEmail(e.target.value)}
                      className="form-control border-0 bg-light px-4"
                      placeholder="Tu Email?"
                      style={{ height: 55 }}
                    />
                  </div>
                  <div className="col-12">
                    <input
                      type="number"
                      onChange={(e) => setTel(e.target.value)}
                      className="form-control border-0 bg-light px-4"
                      placeholder="Tu Telefono?"
                      style={{ height: 55 }}
                    />
                  </div>
                  <div className="col-12">
                    <textarea
                      className="form-control border-0 bg-light px-4 py-3"
                      rows={5}
                      onChange={(e) => setNote(e.target.value)}
                      placeholder="Quieres escribir algun mensaje?"
                      defaultValue={""}
                    />
                  </div>
                  <div className="col-12">
                    <button className="btn btn-primary w-100 py-3" type="submit">
                      Enviar mensaje
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-xl-4 col-lg-12 wow slideInUp" data-wow-delay="0.6s">
              <iframe
                className="position-relative rounded w-100 h-100"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3284.7620417934468!2d-58.42114198457545!3d-34.584887164009395!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb581b9280ea1%3A0x7f033bd286ad1bdf!2sOdin%20Consultorios%20Odontol%C3%B3gicos!5e0!3m2!1ses-419!2sar!4v1679252450328!5m2!1ses-419!2sar"
                frameBorder={0}
                style={{ minHeight: 400, border: 0 }}
                allowFullScreen=""
                aria-hidden="false"
                tabIndex={0}
              />
            </div>
          </div>
        </div>
      </div>
      {/* Contact End */}
      {/* Newsletter Start */}
      {/* <div
        className="container-fluid position-relative pt-5 wow fadeInUp"
        data-wow-delay="0.1s"
        style={{ zIndex: 1 }}
      >
        <div className="container">
          <div className="bg-primary p-5">
            <form className="mx-auto" style={{ maxWidth: 600 }}>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control border-white p-3"
                  placeholder="Your Email"
                />
                <button className="btn btn-dark px-4">Sign Up</button>
              </div>
            </form>
          </div>
        </div>
      </div> */}
      {/* Newsletter End */}
      {/* <Footer /> */}
      {/* Back to Top */}
      <a
        href="#"
        className="btn btn-lg btn-primary btn-lg-square rounded back-to-top"
      >
        <i className="bi bi-arrow-up" />
      </a>
      {/* JavaScript Libraries */}
      {/* Template Javascript */}
    </>
  );
};

export default Contact;
