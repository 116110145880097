import React from 'react'
import { AuthContext } from "../contexts/AuthContext";
import { Container } from "react-bootstrap";
import ScheduleList from "../Components/ScheduleList/ScheduleList";
import LandingPage from '../Components/LandingPage/LandingPage';
import Header from '../Components/Layout/Header/Header';
import Footer from '../Components/Layout/Footer/Footer';

function Home() {
    const { user1, isAuth } = React.useContext(AuthContext);
    return (
        <>
            {isAuth ? (
                <>
                    <Header />
                    <Container className="themed-container" fluid={true}>
                        <ScheduleList />
                    </Container>
                    {isAuth ? null : <Footer />}
                </>
            ) : (
                <LandingPage />
            )}
        </>
    )
}

export default Home