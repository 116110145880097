import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Table, Button, Col, Row, } from "react-bootstrap";
import axios from "axios";
import Header from "../Layout/Header/Header";
import BotComment from "../CommentsList.jsx/BotComment";
import SobreTurno from "../Apointment/SobreTurno";
import exportFromJSON from "export-from-json";
import Expediente from "../Formulario/Expediente";
import ReactLoading from 'react-loading'
import "../../index.css";
import './List.css'
import '../Formulario/Expediente.css'
// import DeleteUser from "../Delete/DeleteUser";

function List(props) {
  const { isAuth, user1 } = useContext(AuthContext);
  const [data1, setData1] = useState([]);
  const [dataxls, setDataxls] = useState([]);
  const [order, setOrder] = useState("first_name");
  const [list, setList] = useState(props.lista);
  const [users, setUsers] = useState([]);
  const [searchText, setSearchText] = useState("");
  let [loading, setLoading] = useState(false)
  const URL_GET_ADMINS = `${process.env.REACT_APP_API}${props.lista}/${user1.id}`;
  const excludeColumns = ["_id", "is_active", "createdAt", "password", "updatedAt",]; // excluye datos del arreglo del filtro

  // handle change event of search input
  const handleChange = (value) => {
    setSearchText(value);
    filterData(value);
  };

  useEffect(() => {
    setLoading(true)
    if (user1.id != undefined) {
      axios
        .get(URL_GET_ADMINS, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then(
          // eslint-disable-next-line
          (data) => (setUsers(data.data), setData1(data.data), setSearchText(""), setLoading(false))
        )
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line
  }, [user1]);

  useEffect(() => {
    verify();
    // eslint-disable-next-line
  }, [data1]);

  const verify = () => {
    if (list === "admins") {
      setList("admins");
    }
    if (list === "doctors") {
      setList("doctors");
    }
    if (list === "secres") {
      setList("secres");
    }
    if (list === "usuarios") {
      setList("usuarios");
    }
  };

  // filter records by search text
  const filterData = (value) => {
    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === "") setData1(users);
    else {
      const filteredData = users.filter((item) => {
        return Object.keys(item).some((key) =>
          excludeColumns.includes(key)
            ? false
            : String(item[key]).toLowerCase().includes(lowercasedValue)
        );
      });
      setData1(filteredData);
    }
  };

  const sortJSON = (json, key, orden) => {
    // eslint-disable-next-line
    return json.sort(function (a, b) {
      var x = a[key],
        y = b[key];

      if (orden === "asc") {
        return x < y ? -1 : x > y ? 1 : 0;
      }

      if (orden === "desc") {
        return x > y ? -1 : x < y ? 1 : 0;
      }
    });
  };

  sortJSON(data1, order, "asc");

  const data = dataxls;
  const fileName = "ReporteContactos";
  const exportType = "csv";

  const xls = () => {
    exportFromJSON({ data, fileName, exportType });
  };

  useEffect(() => {
    let list1 = list;
    if (list1 === "admins") {
      let fname = data1.map((v) => v.nombre);
      let lname = data1.map((v) => v.apellido);
      let email = data1.map((v) => v.email);
      let tel = data1.map((v) => v.celular);

      let datos = [];

      for (var i = 0; i < fname.length; i++) {
        datos.push({
          Nombre: fname[i],
          Apellido: lname[i],
          Email: email[i],
          Telefono: tel[i],
        });
        setDataxls(datos);
      }
    } else if (list1 === "doctors") {
      let fname = data1.map((v) => v.nombre);
      let lname = data1.map((v) => v.apellido);
      let country = data1.map((v) => v.country);
      let specialty = data1.map((v) => v.specialty);
      let email = data1.map((v) => v.email);
      let tel = data1.map((v) => v.celular);

      let datos = [];

      for (let i = 0; i < fname.length; i++) {
        datos.push({
          Nombre: fname[i],
          Apellido: lname[i],
          Pais: country[i],
          Especialidad: specialty[i],
          Email: email[i],
          Telefono: tel[i],
        });
        setDataxls(datos);
      }
    }
    else if (list1 === "secres") {
      let fname = data1.map((v) => v.nombre);
      let lname = data1.map((v) => v.apellido);
      let email = data1.map((v) => v.email);
      let tel = data1.map((v) => v.celular);

      let datos = [];

      for (let i = 0; i < fname.length; i++) {
        datos.push({
          Nombre: fname[i],
          Apellido: lname[i],
          Email: email[i],
          Telefono: tel[i],
        });
        setDataxls(datos);
      }
    } else if (list1 === "usuarios") {
      let fname = data1.map((v) => v.nombre);
      let lname = data1.map((v) => v.apellido);
      let age = data1.map((v) => v.age);
      let country = data1.map((v) => v.country);
      let comunity = data1.map((v) => v.comunity);
      let email = data1.map((v) => v.email);
      let tel = data1.map((v) => v.celular);

      let datos = [];

      for (let i = 0; i < fname.length; i++) {
        datos.push({
          Nombre: fname[i],
          Apellido: lname[i],
          Edad: age[i],
          Pais: country[i],
          Comunidad: comunity[i],
          Email: email[i],
          Telefono: tel[i],
        });
        setDataxls(datos);
      }
    }
    // eslint-disable-next-line
  }, [data1]);

  return (
    <>
      {isAuth ? (
        <div className="noprint">
          <Header />
          <br />
          <Row>
            <Col>
              <h1 className="margin">{props.titulo}</h1>
            </Col>
            {user1.role == "admin" ?
              list != "usuarios" ?
                <Col>
                  <Button
                    href={list == "admins" ? "/signupadmin" : list == "doctors" ? "/signupdoctor" : list == "secres" ? "/signupsecre" : null}
                    variant="success"
                    className="float"
                  >
                    Nuevo
                  </Button>
                </Col>
                : null
              : null}
          </Row>
          {user1.role == "admin" ?
            <div className="float">
              <Button
                variant="outline-success rounded-circle "
                onClick={xls}
                className="margin"
              >
                {" "}
                <i className="far fa-file-excel"></i>
              </Button>
            </div>
            : null}
          <input
            className="w3-input w3-border w3-animate-input buscador"
            type="text"
            placeholder="Busqueda"
            value={searchText}
            onChange={(e) => handleChange(e.target.value)}
          ></input>
          <Table id="table" responsive hover size="sm">
            <thead>
              <tr>
                <th
                  className="texto"
                  onClick={() => setOrder("first_name")}
                  variant="link"
                  size="sm"
                >
                  Nombre
                </th>
                <th
                  className="texto"
                  onClick={() => setOrder("last_name")}
                  variant="light"
                  size="sm"
                >
                  Apellido
                </th>
                {list == "doctors" ?
                  <th
                    className="texto"
                    onClick={() => setOrder("specialty")}
                    variant="light"
                    size="sm"
                  >
                    Especiaidad
                  </th>
                  : null}
                <th
                  className="texto"
                  onClick={() => setOrder("email")}
                  variant="light"
                  size="sm"
                >
                  Email
                </th>
                {list == "doctors" || list == "usuarios" || list == "secres" ?
                  user1.role == "admin" || user1.role == "secre" ?
                    <th
                      className="texto"
                      onClick={() => setOrder("dni")}
                      variant="light"
                      size="sm"
                    >
                      Dni
                    </th>
                    : null
                  : null}
                <th
                  className="texto"
                  onClick={() => setOrder("tel")}
                  variant="light"
                  size="sm"
                >
                  Telefono
                </th>
                {user1.role != "user" && list == "usuarios" ?
                  < th className="texto">Expediente</th>
                  : null}
                {user1.role == "doctor" || user1.role == "secre" ?
                  list == "usuarios" ?
                    <th className="texto">Sobre Turno</th>
                    : null
                  : null}
                {list == "admins" ? null :
                  <th className="texto">Comentarios</th>
                }
                {/* <th className="texto">Eliminar</th> */}
              </tr>
            </thead>
            <tbody>
              {data1.map((user, i) => (
                <tr key={i}>
                  <td className="texto">{user.nombre.toLowerCase().replace(/^\w/, c => c.toUpperCase())}</td>
                  <td className="texto">{user.apellido.toLowerCase().replace(/^\w/, c => c.toUpperCase())}</td>
                  {list == "doctors" ?
                    <td className="texto">{user.especialidad.map((item) => item + " ")}</td>
                    : null}
                  <td className="texto">{user.email}</td>
                  {list == "doctors" || list == "usuarios" || list == "secres" ?
                    user1.role == "admin" || user1.role == "secre" ?
                      <td className="texto">{user.dni}</td>
                      : null
                    : null}
                  <td className="texto">{user.celular}</td>
                  {user1.role != "user" && list == "usuarios" ?
                    <td className="texto"><Expediente user={user} /></td>
                    : null}
                  {user1.role == "doctor" || user1.role == "secre" ?
                    list == "usuarios" ?
                      <td className="texto">
                        <SobreTurno
                          id={user._id}
                          nombre={user.nombre.toLowerCase().replace(/^\w/, c => c.toUpperCase())}
                          apellido={user.apellido.toLowerCase().replace(/^\w/, c => c.toUpperCase())}
                        />
                      </td>
                      : null
                    : null}
                  {list == "admins" ? null :
                    <td className="texto">
                      <BotComment
                        id={user._id}
                        first_name={user.nombre.toLowerCase().replace(/^\w/, c => c.toUpperCase())}
                        last_name={user.apellido.toLowerCase().replace(/^\w/, c => c.toUpperCase())}
                      />
                    </td>
                  }
                  {/* <td className="texto">
                            <DeleteUser
                              id={user._id}
                              route1={"schedulesbyuser"}
                              route={"deleteadmins"}
                              route2={"commentsbyauthor"}
                              route3={"commentsbyabout"}
                            />
                          </td> */}
                </tr>
              ))}
            </tbody>
          </Table>
          {loading ?
            <center>
              <div className='loading'><ReactLoading type={'spin'} color={'cornflowerblue'} height={'5vw'} width={'5vw'} /></div>
            </center>
            : null}
        </div>
      ) : undefined
      }
    </>
  );
}

export default List;
