import React from "react";
import "../../../index.css";

function Footer() {
  return (
    <>
      {/* Footer Start */}
      <div
        className="container-fluid bg-dark text-light py-5 wow fadeInUp"
        data-wow-delay="0.3s"
        style={{ marginTop: "-75px" }}
      >
        <div className="container pt-5">
          <div className="row g-5 pt-4">
            <div className="col-lg-3 col-md-6">
              <h3 className="text-white mb-4">Enlaces rapidos</h3>
              <div className="d-flex flex-column justify-content-start">
                <a className="text-light mb-2" href="#">
                  <i className="bi bi-arrow-right text-primary me-2" />
                  Home
                </a>
                <a className="text-light mb-2" href="#">
                  <i className="bi bi-arrow-right text-primary me-2" />
                  Acerca de nosotros
                </a>
                {/* <a className="text-light mb-2" href="#">
                  <i className="bi bi-arrow-right text-primary me-2" />
                  Nuestros servicios
                </a>
                <a className="text-light mb-2" href="#">
                  <i className="bi bi-arrow-right text-primary me-2" />
                  Nuestro Blog
                </a>
                <a className="text-light" href="#">
                  <i className="bi bi-arrow-right text-primary me-2" />
                  Contactanos
                </a> */}
              </div>
            </div>
            {/* <div className="col-lg-3 col-md-6">
              <h3 className="text-white mb-4">Enlaces populares</h3>
              <div className="d-flex flex-column justify-content-start">
                <a className="text-light mb-2" href="#">
                  <i className="bi bi-arrow-right text-primary me-2" />
                  Home
                </a>
                <a className="text-light mb-2" href="#">
                  <i className="bi bi-arrow-right text-primary me-2" />
                  Acerca de nosotros
                </a>
                <a className="text-light mb-2" href="#">
                  <i className="bi bi-arrow-right text-primary me-2" />
                  Nuestros servicios
                </a>
                <a className="text-light mb-2" href="#">
                  <i className="bi bi-arrow-right text-primary me-2" />
                  Nuestro Blog
                </a>
                <a className="text-light" href="#">
                  <i className="bi bi-arrow-right text-primary me-2" />
                  conactanos
                </a>
              </div>
            </div> */}
            <div className="col-lg-3 col-md-6">
              <h3 className="text-white mb-4">Visitanos</h3>
              <p className="mb-2">
                <i className="bi bi-geo-alt text-primary me-2" />
                Güemes 4139 1425, CABA
              </p>
              <p className="mb-2">
                <i className="bi bi-envelope-open text-primary me-2" />
                info@consultoriosodin.com.ar
              </p>
              <p className="mb-0">
                <i className="bi bi-telephone text-primary me-2" />
                (011)4833-3267 &nbsp;
              </p>
            </div>
            {/* <div className="col-lg-3 col-md-6">
              <h3 className="text-white mb-4">Redes Sociales</h3>
              <div className="d-flex">
                <a
                  className="btn btn-lg btn-primary btn-lg-square rounded me-2"
                  href="#"
                >
                  <i className="fab fa-twitter fw-normal" />
                </a>
                <a
                  className="btn btn-lg btn-primary btn-lg-square rounded me-2"
                  href="#"
                >
                  <i className="fab fa-facebook-f fw-normal" />
                </a>
                <a
                  className="btn btn-lg btn-primary btn-lg-square rounded me-2"
                  href="#"
                >
                  <i className="fab fa-linkedin-in fw-normal" />
                </a>
                <a
                  className="btn btn-lg btn-primary btn-lg-square rounded"
                  href="#"
                >
                  <i className="fab fa-instagram fw-normal" />
                </a>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div
        className="container-fluid text-light py-4"
        style={{ background: "#051225" }}
      >
        <div className="container">
          <div className="row g-0">
            <div className="col-md-6 text-center text-md-start">
              <p className="mb-md-0">
                ©{" "}
                <a className="text-white border-bottom" href="#">
                  Odin Consultorios
                </a>
                <br />
                Todos los derechos reservados.
              </p>
            </div>
            <div className="col-md-6 text-center text-md-end">
              <p className="mb-0">
                Powered by{" "}
                {/* <a
                  className="text-white border-bottom"
                  href="https://htmlcodex.com"
                >
                  FuszionCode
                </a> */}
                  <img style={{width:"30%"}} src="img/logo.png" alt="Image" />
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Footer End */}
    </>
  );
}

export default Footer;