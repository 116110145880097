/* eslint-disable */
import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Button, Table, DropdownButton, Dropdown, Modal } from "react-bootstrap";
import DeleteSchedule from "../Delete/DeleteSchedule";
import EditSchedule from "../Edit/EditSchedule";
import Note from "../Note/Note";
import axios from "axios";
import "../../index.css";
// import encode from "nodejs-base64-encode";
import Swal from "sweetalert2";
import img from "../../contexts/ImgContext";
import exportFromJSON from "export-from-json";
import "jspdf-autotable";
import { jsPDF } from "jspdf";
import useWindowWidthAndHeight from "../useWindowWidthAndHeight/useWindowWidthAndHeight";
import dayjs from 'dayjs'
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
registerLocale('es', es)
import "./ScheduleList.css"
import DeleteAllDatesByDoctor from "../Delete/DeleteAllDatesByDoctor";
import Expediente from "../Formulario/Expediente";
import Apointment from "../Apointment/Apointment";
import ReactLoading from 'react-loading'

function ScheduleList(props) {
  const { user1, isAuth } = useContext(AuthContext);
  let [loading, setLoading] = useState(false)
  const [schedule, setSchedule] = useState([]);
  let [data1, setData1] = useState([]);
  let [data3, setData3] = useState([]);
  const [order, setOrder] = useState("date");
  const [searchText, setSearchText] = useState("Turnos por dia");
  const excludeColumns = ["_id", "is_active", "createdAt", "updatedAt"]; // excluye datos del arreglo del filtro
  const URL_GET_SCHEDULE = `${process.env.REACT_APP_API}${user1.role == "secre" ? "schedules" : user1.role == "admin" ? "schedules" : user1.role == "doctor" ? "schedulesbydoctor" : user1.role == "user" ? "schedulesbyuser" : null}/${user1.id}/${user1.role == "doctor" || user1.role == "user" ? user1.id : ""}`;
  const [attachment, setAttachment] = useState("");
  // const URLSENDREPORT = `${process.env.REACT_APP_API}sendreport/`;
  // const [email, setEmail] = useState("Correo electronico");
  const [dataxls, setDataxls] = useState([]);
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  // const [show, setShow] = useState(false);
  // const [width, height] = useWindowWidthAndHeight();
  const [startDate, setStartDate] = useState(null);
  const SCHEDULEPATCH = `${process.env.REACT_APP_API}asistencia`;
  const USERPATCH = `${process.env.REACT_APP_API}editusers`;
  const [tituloBot, setTituloBot] = useState("Anteriores/Poteriores")
  const [tituloBot2, setTituloBot2] = useState("Por profesional")
  const [tituloBot3, setTituloBot3] = useState("Por usuario")
  const [tituloBot4, setTituloBot4] = useState("Por cobertura")
  const [tituloBot5, setTituloBot5] = useState("Meses")
  const DOCGET = `${process.env.REACT_APP_API}doctors/${user1.id}`;
  const USRGET = `${process.env.REACT_APP_API}users/${user1.id}`;
  const OBRGET = `${process.env.REACT_APP_API}obrasSociales`;
  let [doctors, setDoctors] = useState([]);
  let [users, setUsers] = useState([]);
  let [obras, setObras] = useState([]);
  let meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
  let mesesNumeroAtras = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
  let mesesNumeroAdelante = [1, 2, 3]
  let [show, setShow] = useState(false)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let [citasUsuario, setCitasUsuario] = useState([])

  useEffect(() => {
    setLoading(true)
    if (user1.role != {}) {
      axios
        .get(URL_GET_SCHEDULE, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((data) => {

          let sortedData = data.data.sort((a, b) => {
            let dateA = new Date(a.date), dateB = new Date(b.date);
            return dateA - dateB; // Ordena de menor a mayor.
          })

          setSchedule(sortedData)
          setData1(sortedData)
          setData3(sortedData)
          setLoading(false)
        }
        )
        .catch((err) => console.log(err));

      axios
        .get(DOCGET, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((data) => (setDoctors(data.data)))
        .catch((err) => console.log(err));

      if (user1.role == "admin" || user1.role == "secre" || user1.role == "doctor") {
        axios
          .get(USRGET, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          })
          .then((data) => (setUsers(data.data.filter(x => x.role === "user"))))
          .catch((err) => console.log(err));
      }

      axios
        .get(OBRGET, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((data) => {
          if (user1.role == "admin") {
            setObras(data.data)
          } else {
            setObras(user1.obraSocialAtendida)
          }

        })
        .catch((err) => console.log(err));
    }
  }, [user1]);

  const toFind = (selectedDay) => {
    filterData(`${dayjs(selectedDay).format("YYYY-MM-DD")}T`)
    setSearchText(`${dayjs(selectedDay).format("DD-MM-YYYY")}`)
    setOrder("time")
  };

  const filterData = (value) => {
    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === "") setData1(schedule);
    else {
      let filteredData = schedule.filter((item) => {
        return Object.keys(item).some((key) =>
          excludeColumns.includes(key)
            ? false
            : String(item[key]).toLowerCase().includes(lowercasedValue));
      });
      filteredData = filteredData.sort((a, b) => (a.Hora > b.Hora ? 1 : -1));
      setData1(filteredData);
    }
  };

  const Todas = () => {
    setData1(schedule);
    setSearchText(null);
    setSearchText("Turnos por dia");
    setTituloBot("Anteriores/Poteriores");
    setTituloBot2("Por profesional");
    setTituloBot3("Por usuario");
    setTituloBot4("Por cobertura");
    setTituloBot5("Meses");

  };

  const sortJSON = (json, key, orden) => {
    return json.sort(function (a, b) {
      var x = a[key],
        y = b[key];

      if (orden === "asc") {
        return x < y ? -1 : x > y ? 1 : 0;
      }

      if (orden === "desc") {
        return x > y ? -1 : x < y ? 1 : 0;
      }
    });
  };

  sortJSON(data1, order, "asc");

  /// DESDE AQUI EMPIEZA LOS REPORTES PDF
  // useEffect(() => {
  //   const doc = new jsPDF();
  //   doc.autoTable({
  //     margin: { top: 50 },
  //   });
  //   doc.text("Mis Citas", 20, 30);
  //   doc.addImage(img, "JPEG", 160, 15, 20, 20);
  //   doc.autoTable({ html: "#table" });
  //   var att = doc.output("arraybuffer");
  //   // var base64File = encode.encode(att, "base64");
  //   // setAttachment(base64File);
  // }, [email]);

  // const sendReport = () => {
  //   axios
  //     .post(
  //       URLSENDREPORT,
  //       {
  //         email,
  //         attachment,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
  //         },
  //       }
  //     )
  //     .then(() => {
  //       Swal.fire({
  //         icon: "success",
  //         title: "Listo!",
  //         confirmButtonText: `Ok`,
  //         timer: 1000,
  //         timerProgressBar: true,
  //         allowEscapeKey: true,
  //       }).then(() => {
  //         window.location.reload();
  //       });
  //     })
  //     .catch((error) => {
  //       let message = error.response.data.message;
  //       Swal.fire({
  //         icon: "error",
  //         title: "Oops...",
  //         text: "Lo sentimos esta acción no se pudo completar " + message,
  //         allowEscapeKey: true,
  //       });
  //       console.log(error);
  //     });
  // };

  // const downloadPdf = () => {
  //   const doc = new jsPDF();
  //   const text = "Mis Citas";
  //   doc.autoTable({
  //     margin: { top: 50 },
  //   });
  //   doc.text(text, 20, 30);
  //   doc.addImage(img, "JPEG", 160, 15, 20, 20);
  //   doc.autoTable({ html: "#table" });
  //   doc.save("MisCitas.pdf");
  // };

  const data = dataxls;
  const fileName = "MisTurnos";
  const exportType = "csv";

  const xls = () => {
    exportFromJSON({ data, fileName, exportType });
  };

  useEffect(() => {

    let data2 = [];

    data1.map((date) => {
      data2.push(date);
    });

    let inf = data2.filter((v) => v.type === true);
    let date = inf.map((v) => v.date.split("T")[0]);
    let usefna = inf.map((v) => v.user[0].nombre);
    let uselna = inf.map((v) => v.user[0].apellido);
    let tel = inf.map((v) => v.user[0].tel);
    let time = inf.map((v) => v.time);
    let docfna = inf.map((v) => v.profesional[0].nombre);
    let doclna = inf.map((v) => v.profesional[0].apellido);

    let datos = [];

    for (var i = 0; i < date.length; i++) {
      datos.push({
        Fecha: date[i],
        Hora: time[i],
        Nombre: usefna[i],
        Apellido: uselna[i],
        Telefono: tel[i],
        NombreDr: docfna[i],
        ApellidoDr: doclna[i],
      });
      datos = datos.sort((a, b) => (a.Hora > b.Hora ? 1 : -1));
      setDataxls(datos);
    }
  }, [data1]);
  /// AQUI TERMINA LOS REPORTES PDF

  function checkCanceladas(citas) {
    let canceloReciente = []
    citas.map((item) => {
      if (dayjs(`${item.split(' ')[0]} ${item.split(' ')[1]}`).$d >= dayjs().subtract(3, 'month').$d) {
        canceloReciente.push(item)
      }
    })
    return canceloReciente.length
  }

  function siVino(idCita, idPaciente) {
    axios.patch(`${SCHEDULEPATCH}/${user1.id}/${idCita}`, { asistio: "si" },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((resDB) => window.location.reload())
      .catch((error) => console.log(error))

    axios.patch(`${USERPATCH}/${user1.id}/${idPaciente}`, { primeraCita: true, pacienteExistente: true },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((resDB) => window.location.reload())
      .catch((error) => console.log(error))
  }

  function noVino(idCita, idPaciente, primeraVez) {
    axios.patch(`${SCHEDULEPATCH}/${user1.id}/${idCita}`, { asistio: "no" },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((resDB) => window.location.reload())
      .catch((error) => console.log(error))

    primeraVez ?
      axios.patch(`${USERPATCH}/${user1.id}/${idPaciente}`, { pacienteExistente: false, primeraCita: false },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((resDB) => window.location.reload())
        .catch((error) => console.log(error))
      : null
  }

  const filtrar = (lista) => {
    if (lista === "anteriores") {
      setData1(data1.filter(x => dayjs(`${x.date.split('T')[0]}${x.time}`) < dayjs().set('hour', 23).set('minute', 59).set('second', 59)))
      setTituloBot("Turnos Anteriores")
    }
    else if (lista === "posteriores") {
      setData1(data1.filter(x => dayjs(`${x.date.split('T')[0]}${x.time}`) > dayjs().set('hour', 23).set('minute', 59).set('second', 59)))
      setTituloBot("Turnos Posteriores")
    }
    else if (lista === "todas") {
      setData1(data3)
      setTituloBot("Todas las Turnos")
    }
  }

  const filtrar2 = (lista, doctorId, doctorNombre, doctorApellido) => {

    if (lista === "filtro") {
      setData1(data1.filter(x => x.profesional[0]._id == doctorId))
      setTituloBot2(`Profesional ${doctorNombre} ${doctorApellido}`)
    }

    else if (lista === "todos") {
      setData1(data3)
      setTituloBot2("Turnos Anteriores/Poteriores")
      setTituloBot2("Filtro por doctor")
      setTituloBot3("Filtro por usuario")
    }
  }

  const filtrar3 = (lista, userId, userNombre, userApellido) => {

    if (lista === "filtro") {
      setData1(data1.filter(x => x.user[0]._id == userId))
      setTituloBot3(`Paciente ${userNombre} ${userApellido}`)
      setTituloBot2("Filtro por profesional")
      setTituloBot("Turnos Anteriores/Poteriores")
    }

    else if (lista === "todos") {
      setData1(data3)
      setTituloBot3("Todos")
      setTituloBot2("Filtro por profesional")
      setTituloBot("Turnos Anteriores/Poteriores")
    }
  }

  const filtrar4 = (lista, obraNombre) => {

    if (lista === "filtro") {
      setData1(data1.filter(x => x.user[0].obraSocial == obraNombre))
      setTituloBot4(`Cobertura ${obraNombre}`)
      setTituloBot3("Filtro por usuario")
      setTituloBot2("Filtro por profesional")
      setTituloBot("Turnos Anteriores/Poteriores")
    }

    else if (lista === "todos") {
      setData1(data3)
      setTituloBot4("Todos")
      setTituloBot3("Filtro por usuario")
      setTituloBot2("Filtro por profesional")
      setTituloBot("Turnos Anteriores/Poteriores")
    }
  }


  return (
    <>
      <Modal size="xl" show={show}>
        <Modal.Header>
          <Modal.Title>Historial de turnos</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          <div className="container">
            <div className="row">
              <div className="col-12">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Fecha</th>
                      <th scope="col">Hora</th>
                      <th scope="col">Motivo</th>
                      <th scope="col">Estado</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      citasUsuario.map((cita, i) => (
                        <tr key={i}>
                          <th scope="row">{dayjs(cita.date).format("DD/MM/YYYY")}</th>
                          <td>{cita.time}</td>
                          <td>{cita.note}</td>
                          <td>{cita.asistio == "si" ? "Realizada" : cita.asistio == "no" ? "Cancelada" : "Pendiente"}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </Modal.Body>

        <Modal.Footer><Button variant="secondary" onClick={handleClose}>Cerrar</Button></Modal.Footer>

      </Modal>

      {isAuth ? (
        <>
          <div className="btns">
            <div>
              {user1.role == "user" ?
                <div>
                  <br />
                  <div className="picker">
                    <Apointment />
                  </div>
                </div>
                :
                <>
                  <div className="floatl">
                    <br />
                    <DatePicker calendarStartDay={0} locale="es" todayButton="Hoy" className="picker" placeholderText={searchText} selected={startDate} dateFormat="dd/MM/yyyy" onChange={(date) => toFind(date)} />
                  </div>
                </>
              }
            </div>
          </div>

          <br />
          <div className="filtros">
            {/* {user1.role == "doctor" ?
              <Button variant="outline-info" onClick={Todas}>Ver todos los turnos</Button>
              : null}
            {user1.role != "user" ?
              <>
                <DropdownButton className="margin" variant="outline-info" title={tituloBot5}>
                  {mesesNumeroAtras.slice(0).reverse().map((mes, i) => (
                    <Dropdown.Item key={i} onClick={() => (setTituloBot5(`${meses[dayjs().subtract(mes, 'month').$M]} ${dayjs().subtract(mes, 'month').$y}`), filterData(dayjs().add(mes, 'month').format('YYYY-MM')))}>{`${meses[dayjs().subtract(mes, 'month').$M]} ${dayjs().subtract(mes, 'month').$y}`}</Dropdown.Item>
                  ))}
                  <Dropdown.Item onClick={() => (setTituloBot5(`${meses[dayjs().$M]} ${dayjs().$y}`), filterData(dayjs().format('YYYY-MM')))}>{`${meses[dayjs().$M]} ${dayjs().$y}`}</Dropdown.Item>
                  {mesesNumeroAdelante.map((mes, i) => (
                    <Dropdown.Item key={i} onClick={() => (setTituloBot5(`${meses[dayjs().add(mes, 'month').$M]} ${dayjs().add(mes, 'month').$y}`), filterData(dayjs().add(mes, 'month').format('YYYY-MM')))}>{`${meses[dayjs().add(mes, 'month').$M]} ${dayjs().add(mes, 'month').$y}`}</Dropdown.Item>
                  ))}
                </DropdownButton>
                <DropdownButton className="margin" variant="outline-info" title={tituloBot}>
                  <Dropdown.Item onClick={() => filtrar("anteriores")}>Turnos Anteriores</Dropdown.Item>
                  <Dropdown.Item onClick={() => filtrar("posteriores")}>Turnos Posteriores</Dropdown.Item>
                  <Dropdown.Item onClick={() => filtrar("todas")}>Todos los Turnos</Dropdown.Item>
                </DropdownButton>
              </>
              : null}
            {user1.role == "admin" || user1.role === "secre" || user1.role == "doctor" ?
              <>
                {user1.role == "doctor" ? null :
                  <DropdownButton className="margin" variant="outline-info" title={tituloBot2}>
                    <Dropdown.Item onClick={() => filtrar2("todos", "", "Todos")}>Todos los profesionales</Dropdown.Item>
                    {doctors.map((doctor, i) => (
                      <Dropdown.Item key={i} onClick={() => filtrar2("filtro", doctor._id, doctor.nombre, doctor.apellido)}>Profesional {doctor.nombre} {doctor.apellido}</Dropdown.Item>
                    ))}
                  </DropdownButton>
                }
                <DropdownButton className="margin" variant="outline-info" title={tituloBot3}>
                  <Dropdown.Item onClick={() => filtrar3("todos", "", "Todos")}>Todos</Dropdown.Item>
                  {users.map((user, i) => (
                    <Dropdown.Item key={i} onClick={() => filtrar3("filtro", user._id, user.nombre, user.apellido)}>Paciente {user.nombre} {user.apellido}</Dropdown.Item>
                  ))}
                </DropdownButton>
              </>
              : null}
            {user1.role == "admin" || user1.role == "doctor" || user1.role === "secre" ?
              <>
                <DropdownButton className="margin" variant="outline-info" title={tituloBot4}>
                  <Dropdown.Item onClick={() => filtrar4("todos", "Todos")}>Todos</Dropdown.Item>
                  {obras.map((obra, i) => (
                    <Dropdown.Item key={i} onClick={() => filtrar4("filtro", obra.nombre)}>Cobertura{obra.nombre}</Dropdown.Item>
                  ))}
                </DropdownButton>
                <Button className="margin" style={{ "maxHeight": "35px" }} variant="warning" onClick={Todas}>Reset</Button>
              </>
              : null} */}
            <DeleteAllDatesByDoctor />
            {/* ///DESDE AQUI EMPIEZA LOS REPORTES PDF */}
            <div>
              {/* <Button
                style={{ marginRight: "5px" }}
                variant="outline-danger rounded-circle"
                onClick={downloadPdf}
              >
                <i className="fas fa-file-pdf"></i>
              </Button> */}
              {/* <Button
                style={{ marginRight: "5px" }}
                variant="outline-primary rounded-circle"
                onClick={handleShow}
              >
                <i className="fas fa-envelope-open-text"></i>
              </Button>
              <Modal show={show} size="sm" onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    {" "}
                    <h6>Exportar</h6>{" "}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Col>
                      <Form.Group>
                        <Form.Control
                          onChange={(e) => setEmail(e.target.value)}
                          type="email"
                          name="email"
                          id="exampleEmail"
                          placeholder="Correo electronico"
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    type="submit"
                    onClick={() => {
                      sendReport();
                    }}
                    className="btn btn-primary rounded-pill"
                  >
                    Enviar
                  </Button>
                </Modal.Footer>
              </Modal> */}
              {user1.role != "user" ?
                <Button
                  style={{ marginRight: "5px" }}
                  variant="outline-success rounded-circle"
                  onClick={xls}
                >
                  <i className="far fa-file-excel"></i>
                </Button>
                : null}
            </div>
            {/* ///AQUI TERMINA LOS REPORTES PDF */}
          </div>

          <br />
          <Table id="table" responsive hover size="sm">
            <thead>
              <tr>
                <th
                  onClick={() => setOrder("date")}
                  variant="link"
                  size="sm"
                >
                  Fecha
                </th>
                <th
                  onClick={() => setOrder("time")}
                  variant="link"
                  size="sm"
                >
                  Hora
                </th>
                {user1.role == "user" ? null :
                  <>
                    <th
                      onClick={() => setOrder("first_name")}
                      variant="link"
                      size="sm"
                    >
                      Nombre
                    </th>
                    <th
                      onClick={() => setOrder("last_name")}
                      variant="link"
                      size="sm"
                    >
                      Apellido
                    </th>
                    <th
                      onClick={() => setOrder("tel")}
                      variant="link"
                      size="sm"
                    >
                      Celular
                    </th>
                  </>
                }
                {user1.role == "admin" || user1.role == "user" || user1.role == "secre" ?
                  <>
                    <th
                      onClick={() => setOrder("first_name")}
                      variant="link"
                      size="sm"
                    >
                      Nombre Dr.
                    </th>
                    <th
                      onClick={() => setOrder("last_name")}
                      variant="link"
                      size="sm"
                    >
                      Apellido Dr.
                    </th>
                  </>
                  : null}
                {user1.role == "doctor" || user1.role == "admin" || user1.role == "secre" ?
                  <>
                    <th>Cancelaciones</th>
                    <th>Asistencia</th>
                    <th>Historial</th>
                    <th>Expediente</th>
                  </>
                  : null}
                <th>Nota</th>
                {user1.role == "admin" || user1.role == "user" || user1.role == "secre" ?
                  <th>Cancelar</th>
                  : null}
                {/* {user1.role == "admin" ?
                  <>
                    <th>Editar</th>
                  </>
                  : null} */}
              </tr>
            </thead>
            <tbody>
              {data1.map((date, i) => {
                // const fecha = new Date(date.date).valueOf();
                // const now = Date.now();
                // const past = dayjs().subtract(1, "year").valueOf();
                // let check = user1.role == "admin" || user1.role == "secre" || user1.role == "doctor" ? past : now

                //esta linea va en la validacion que empezamos abajo para solo mostrar las del futuro
                // && fecha >= check  
                return (
                  date.type === true ?
                    <tr style={date.asistio == "si" ? { "backgroundColor": "rgba(23, 240, 7,0.2)" } : date.asistio == "no" ? { "backgroundColor": "rgba(222, 29, 16, 0.2)" } : null} key={i}>
                      <td>{`${dayjs(date.date).format("DD-MM-YYYY")}`}</td>
                      <td>{date.time}</td>
                      {user1.role == "user" ? null :
                        <>
                          <td>{date.user[0].nombre.toLowerCase().replace(/^\w/, c => c.toUpperCase())}</td>
                          <td>{date.user[0].apellido.toLowerCase().replace(/^\w/, c => c.toUpperCase())}</td>
                          <td>{date.user[0].celular}</td>
                        </>
                      }
                      {user1.role == "admin" || user1.role == "user" || user1.role == "secre" ?
                        <>
                          <td>{date.profesional[0].nombre.toLowerCase().replace(/^\w/, c => c.toUpperCase())}</td>
                          <td>{date.profesional[0].apellido.toLowerCase().replace(/^\w/, c => c.toUpperCase())}</td>
                        </>
                        : null}
                      {user1.role == "doctor" || user1.role == "admin" || user1.role == "secre" ?
                        <>
                          <td style={checkCanceladas(date.user[0].listaCancelaciones) >= 3 ? { "color": "red" } : checkCanceladas(date.user[0].listaCancelaciones) < 3 && checkCanceladas(date.user[0].listaCancelaciones) >= 2 ? { "color": "orange" } : checkCanceladas(date.user[0].listaCancelaciones) < 2 && checkCanceladas(date.user[0].listaCancelaciones) >= 1 ? { "color": "green" } : null}>{checkCanceladas(date.user[0].listaCancelaciones)}</td>
                          <td style={{ minWidth: '7.5vw' }}><Button style={{ marginRight: "3px", minWidth: "3rem" }} disabled={dayjs() > dayjs(`${date.date.split('T')[0]}${date.time}`).add(8, 'hour') ? true : false} variant="success" onClick={() => siVino(date._id, date.user[0]._id, date.primeraVez)}><i className="fas fa-check"></i></Button><Button style={{ minWidth: "3rem" }} disabled={dayjs() > dayjs(`${date.date.split('T')[0]}${date.time}`).add(8, 'hour') ? true : false} variant="danger" onClick={() => noVino(date._id, date.user[0]._id, date.primeraVez)}><i className="fas fa-times"></i></Button></td>
                          <td>
                            <div className="centrado">
                              <Button onClick={() => {
                                handleShow()
                                let citasUsuario = data1.filter(x => x.user[0]._id == date.user[0]._id)
                                setCitasUsuario(citasUsuario)
                              }}><i className="far fa-solid fa-file"></i></Button>
                            </div>
                          </td>
                          <td><div className="centrado"><Expediente user={date.user[0]} /></div>                            </td>
                        </>
                        : null}

                      {/* {user1.role == "admin" ?
                      <>
                        <td>
                          {
                            <EditSchedule
                              id={date._id}
                              datee={date.date}
                              timee={date.time}
                              notee={date.note}
                              doctore={date.profesional[0]._id}
                              doctorefn={date.profesional[0].nombre}
                              doctoreln={date.profesional[0].apellido}
                            />
                          }
                        </td>
                      </>
                      : null} */}
                      <td>{<Note note={date.note} />}</td>
                      {user1.role == "admin" || user1.role == "user" || user1.role == "secre" ?
                        <td>
                          <div className="centrado">
                            {<DeleteSchedule userId={date.user[0]._id} id={date._id} datee={date.date} timee={date.time} primeraVez={date.primeraVez} />}
                          </div>
                        </td>
                        : null}
                    </tr>
                    : undefined
                )
              })}
            </tbody>
          </Table>
          {loading ?
            <center>
              <div className='loading'><ReactLoading type={'spin'} color={'cornflowerblue'} height={'5vw'} width={'5vw'} /></div>
            </center>
            : null}
        </>
      ) : undefined}
    </>
  );
}

export default ScheduleList;
