import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { Button, Modal, Form, Nav } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
// import "bootstrap/dist/css/bootstrap.min.css";
import "../../index.css";

const Login = (props) => {
  const [dni, setDni] = useState("");
  const [password, setPassword] = useState("");
  const [dniForgot, setDniForgot] = useState("");
  const { loginUser } = useContext(AuthContext);
  const LOGIN_URL = `${process.env.REACT_APP_API}login/`;
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [showForgot, setShowForgot] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const jsonSend = { dni, password };

    try {
      const res = await axios.post(LOGIN_URL, jsonSend)
      loginUser(res.data.token);
      Swal.fire({
        icon: "success",
        title: "Bienvenido",
        timer: 1000,
        timerProgressBar: true,
        allowEscapeKey: true,
      }).then(history.push("/"));
    } catch (error) {
      console.log(Error)
      // let message = error.response.data.message
      Swal.fire({
        icon: "error",
        title: "Error en iniciar sesion",
        // text: message,
        allowEscapeKey: true,
      });
    }
  };

  function handleForgot(dniForgot) {
    axios.post(`${process.env.REACT_APP_API}/forgotPassword`, { dni: dniForgot })
      .then(() => {
        Swal.fire('Correo enviado con exito!, sigue las instrucciones que ahi se indican')
          .then(() => { setShowForgot(false); setShow(false) })
          .catch((err) => { Swal.fire('Ocurrio un error, intenta de nuevo por favor.') })
      })
      .catch((err) => { Swal.fire('Ocurrio un error, intenta de nuevo por favor.') })
  }

  return (
    <>
      <a onClick={handleShow} className={props.landing ? "" : "btn btn-primary py-2 px-4 ms-3"} style={{ color: "white", minWidth: `${props.tamaño}` }}>
        {props.titulo}
      </a>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicEmail">
              {/* <Form.Label>Ingresar número de documento</Form.Label> */}
              <Form.Control
                value={dni}
                onChange={(e) => setDni(e.target.value)}
                type="number"
                name="dni"
                placeholder="Ingresar número de documento ó dni"
                required
              />
            </Form.Group>
            <br />
            <Form.Group controlId="formBasicPassword">
              {/* <Form.Label>Contraseña</Form.Label> */}
              <Form.Control
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                name="password"
                placeholder="Contraseña"
                required
              />
            </Form.Group>
            <br />
            <center>

              <Button className="h1" type="submit">
                Iniciar Sesión
              </Button>

              <Button variant="link" onClick={() => { setShowForgot(true) }}>¿Olvidaste tu contraseña?</Button>

            </center>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={showForgot} onHide={() => setShowForgot(false)}>
        <Modal.Body>
          <Form>
            <Form.Group>
              <br />
              <Form.Label>Te enviaremos por email las instrucciones para reestablecer tu contraseña</Form.Label>
              <br /><br />
              <Form.Control type="email" placeholder="Ingresa tu DNI" onChange={(e) => setDniForgot(e.target.value)} />
            </Form.Group>
            <br />
            <center>
              <Button className="h1" variant="danger" onClick={() => setShowForgot(false)}>Cancelar</Button>
              <Button className="h1" variant="success" onClick={() => handleForgot(dniForgot)}>Enviar</Button>
            </center>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Login;
