import React, { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import Login from "../Users/Login";
import Header from "../../Components/Layout/Header/Header";
import Footer from '../Layout/Footer/Footer';


function LandingPage() {
    const { isAuth, user1 } = useContext(AuthContext);
    return (
        <>
            {/* Spinner Start */}
            {/* <div
                    id="spinner"
                    className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
                    >
                    <div className="spinner-grow text-primary m-1" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-dark m-1" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-secondary m-1" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    </div> */}
            {/* Spinner End */}
            {/* Topbar Start */}
            {/* <div className="container-fluid bg-light ps-5 pe-0 d-none d-lg-block">
                <div className="row gx-0">
                    <div className="col-md-6 text-center text-lg-start mb-2 mb-lg-0">
                        <div className="d-inline-flex align-items-center">
                            <small className="py-2">
                                <i className="far fa-clock text-primary me-2" />
                                Horarios de atención: Lun - Vie : 9.30 am - 7.00 pm, Domingos Cerrado{" "}
                            </small>
                        </div>
                    </div>
                    <div className="col-md-6 text-center text-lg-end">
                        <div className="position-relative d-inline-flex align-items-center bg-primary text-white top-shape px-5">
                            <div className="me-3 pe-3 border-end py-2">
                                <p className="m-0">
                                    <i className="fa fa-envelope-open me-2" />
                                    info@ejemplo.com
                                </p>
                            </div>
                            <div className="py-2">
                                <p className="m-0">
                                    <i className="fa fa-phone-alt me-2" />
                                    5491125816420 &nbsp;
                                    <i className="fab fa-whatsapp"></i>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* Topbar End */}
            {/* Navbar Start */}
            {/* <nav className="navbar navbar-expand-lg bg-white navbar-light shadow-sm px-5 py-3 py-lg-0">
<a href="index.html" className="navbar-brand p-0">
    <h1 className="m-0 text-primary">
        <i className="fa fa-tooth me-2" />
        Consultorios Odin
    </h1>
</a>
<button
    className="navbar-toggler"
    type="button"
    data-bs-toggle="collapse"
    data-bs-target="#navbarCollapse"
>
    <span className="navbar-toggler-icon" />
</button>
<div className="collapse navbar-collapse" id="navbarCollapse">
    <div className="navbar-nav ms-auto py-0">
        <a href="index.html" className="nav-item nav-link active">
            Home
        </a>
        <a href="about.html" className="nav-item nav-link">
            About
        </a>
        <a href="service.html" className="nav-item nav-link">
            Service
        </a>
        <div className="nav-item dropdown">
            <a
                href="#"
                className="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
            >
                Pages
            </a>
            <div className="dropdown-menu m-0">
                <a href="price.html" className="dropdown-item">
                    Pricing Plan
                </a>
                <a href="team.html" className="dropdown-item">
                    Our Dentist
                </a>
                <a href="testimonial.html" className="dropdown-item">
                    Testimonial
                </a>
                <a href="appointment.html" className="dropdown-item">
                    Appointment
                </a>
            </div>
        </div>
        <a href="contact.html" className="nav-item nav-link">
            Contact
        </a>
    </div>
    
    <button
        type="button"
        className="btn text-dark"
        data-bs-toggle="modal"
        data-bs-target="#searchModal"
    >
        <i className="fa fa-search" />
    </button>
    <Login />
</div>
</nav> */}
            {/* Navbar End */}
            {/* Full Screen Search Start */}
            <Header />
            <div className="modal fade" id="searchModal" tabIndex={-1}>
                <div className="modal-dialog modal-fullscreen">
                    <div
                        className="modal-content"
                        style={{ background: "rgba(9, 30, 62, .7)" }}
                    >
                        <div className="modal-header border-0">
                            <button
                                type="button"
                                className="btn bg-white btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body d-flex align-items-center justify-content-center">
                            <div className="input-group" style={{ maxWidth: 600 }}>
                                <input
                                    type="text"
                                    className="form-control bg-transparent border-primary p-3"
                                    placeholder="Type search keyword"
                                />
                                <button className="btn btn-primary px-4">
                                    <i className="bi bi-search" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Full Screen Search End */}
            {/* Carousel Start */}
            {/* <div className="container-fluid p-0">
                <div
                    id="header-carousel"
                    className="carousel slide carousel-fade"
                    data-bs-ride="carousel"
                >
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img className="w-100" src="img/carousel-1.jpg" alt="Image" />
                            <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                                <div className="p-3" style={{ maxWidth: 900 }}>
                                    <h5 className="text-white text-uppercase mb-3 animated slideInDown">
                                        Cuida la salud de tus dientes
                                    </h5>
                                    <h1 className="display-1 text-white mb-md-4 animated zoomIn">
                                        Toma el tratamiento de mejor calidad
                                    </h1>
                                    <a
                                        href=""
                                        className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                                    >
                                        Turnos
                                    </a>
                                    <a
                                        href=""
                                        className="btn btn-secondary py-md-3 px-md-5 animated slideInRight"
                                    >
                                        Registrate
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img className="w-100" src="img/carousel-2.jpg" alt="Image" />
                            <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                                <div className="p-3" style={{ maxWidth: 900 }}>
                                    <h5 className="text-white text-uppercase mb-3 animated slideInDown">
                                        Cuida la salud de tus dientes
                                    </h5>
                                    <h1 className="display-1 text-white mb-md-4 animated zoomIn">
                                        Toma el tratamiento de mejor calidad
                                    </h1>
                                    <a
                                        href="appointment.html"
                                        className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                                    >
                                        Turnos
                                    </a>
                                    <a
                                        href=""
                                        className="btn btn-secondary py-md-3 px-md-5 animated slideInRight"
                                    >
                                        Registrate
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button
                        className="carousel-control-prev"
                        type="button"
                        data-bs-target="#header-carousel"
                        data-bs-slide="prev"
                    >
                        <span className="carousel-control-prev-icon" aria-hidden="true" />
                        <span className="visually-hidden">Anterior</span>
                    </button>
                    <button
                        className="carousel-control-next"
                        type="button"
                        data-bs-target="#header-carousel"
                        data-bs-slide="next"
                    >
                        <span className="carousel-control-next-icon" aria-hidden="true" />
                        <span className="visually-hidden">Siguiente</span>
                    </button>
                </div>
            </div> */}
            {/* Carousel End */}
            {/* Banner Start */}
            {/* <div className="container-fluid banner mb-5">
                <div className="container">
                    <div className="row gx-0">
                        <div className="col-lg-4 wow zoomIn" data-wow-delay="0.1s">
                            <div
                                className="bg-primary d-flex flex-column p-5"
                                style={{ height: 300 }}
                            >
                                <h3 className="text-white mb-3">Horarios de atención</h3>
                                <div className="d-flex justify-content-between text-white mb-3">
                                    <h6 className="text-white mb-0">Lun - Vie</h6>
                                    <p className="mb-0"> 8:00am - 9:00pm</p>
                                </div>
                                <div className="d-flex justify-content-between text-white mb-3">
                                    <h6 className="text-white mb-0">Sabados</h6>
                                    <p className="mb-0"> 8:00am - 7:00pm</p>
                                </div>
                                <div className="d-flex justify-content-between text-white mb-3">
                                    <h6 className="text-white mb-0">Domingos</h6>
                                    <p className="mb-0"> 8:00am - 5:00pm</p>
                                </div>
                                <a className="btn btn-light" href="">
                                    Turnos
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 wow zoomIn" data-wow-delay="0.3s">
                            <div
                                className="bg-dark d-flex flex-column p-5"
                                style={{ height: 300 }}
                            >
                                <h3 className="text-white mb-3">Busca un profesional</h3>
                                <div className="date mb-3" id="date" data-target-input="nearest">
                                <input
                                    type="text"
                                    className="form-control bg-light border-0 datetimepicker-input"
                                    placeholder="Appointment Date"
                                    data-target="#date"
                                    data-toggle="datetimepicker"
                                    style={{ height: 40 }}
                                />
                            </div>
                                <select
                                    className="form-select bg-light border-0 mb-3"
                                    style={{ height: 40 }}
                                >
                                    <option value="">Escoge un servicio</option>
                                    <option value={1}>Especialidad 1</option>
                                    <option value={2}>Especialidad 2</option>
                                    <option value={3}>Especialidad 3</option>
                                </select>
                                <a className="btn btn-light" href="">
                                    Busca doctor
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 wow zoomIn" data-wow-delay="0.6s">
                            <div
                                className="bg-secondary d-flex flex-column p-5"
                                style={{ height: 300 }}
                            >
                                <h3 className="text-white mb-3">Pide un turno</h3>
                                <p className="text-white">
                                    Ipsum erat ipsum dolor clita rebum no rebum dolores labore, ipsum
                                    magna at eos et eos amet.
                                </p>
                                <h2 className="text-white mb-0">
                                    <i className="fa fa-phone-alt me-2" />                                    
                                    <i className="fab fa-whatsapp"></i> &nbsp;
                                    5491125816420
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* Banner Start */}
            {/* About Start */}
            <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-7">
                            <h1 className="display-5 mb-0">
                                Consultorios Odin
                            </h1>
                            <h3 className="text-body fst-italic mb-4">
                                Bienvenido a nuestra nueva pagina de turnos
                            </h3>

                            <a
                                href="/register?new=true"
                                className="btn btn-primary py-3 px-4 mt-2 wow zoomIn"
                                data-wow-delay="0.6s"
                                style={{ minWidth: "100%", maxWidth: "100%", minHeight:"5%" }}
                            >
                                Soy paciente de primera vez, nunca me atendi en Odin
                            </a>
                            <a
                                href="/register?new=false"
                                className="btn btn-primary py-3 px-4 mt-2 wow zoomIn"
                                data-wow-delay="0.6s"
                                style={{ minWidth: "100%", maxWidth: "100%", minHeight:"5%" }}
                            >
                                Soy paciente de Odin pero nunca me registre en el nuevo sistema
                            </a>
                            <br /> 

                            {isAuth ? null :
                                <>
                                    <div
                                        className="btn btn-primary py-3 px-4 mt-2 wow zoomIn"
                                        data-wow-delay="0.6s"
                                        style={{ minWidth: "100%", maxWidth: "100%", minHeight:"5%" }}
                                    >
                                    <Login titulo="Soy paciente de odin y ya me registre en el nuevo sistema" tamaño="75%" landing={true} />
                                    </div>

                                </>



                            }

                            <br /><br /><br />
                            <div className="section-title mb-4">
                                <h5 className="position-relative d-inline-block text-primary text-uppercase">
                                    Acerca de nosotros
                                </h5>
                            </div>
                            <p className="mb-4">
                                Bienvenidos al Consultorio Odín, tu destino para una atención dental excepcional en Buenos Aires.

                                En el Consultorio Odín, nos enorgullece brindar servicios dentales de calidad con un enfoque personalizado para cada paciente. Nuestro equipo de profesionales altamente capacitados y amables está comprometido a brindarte una experiencia dental cómoda y satisfactoria.

                                <br />

                                Tu salud bucal es nuestra prioridad, y nos comprometemos a brindarte el más alto nivel de atención dental en cada paso del camino. Queremos ayudarte a mantener una sonrisa saludable y hermosa que puedas lucir con confianza.

                                <br /><br />

                                ¡El Consultorio Odín está aquí para cuidar de tu sonrisa!
                            </p>
                            {/* <div className="row g-3">
                                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.3s">
                                    <h5 className="mb-3">
                                        <i className="fa fa-check-circle text-primary me-3" />
                                        Nuestros premios
                                    </h5>
                                    <h5 className="mb-3">
                                        <i className="fa fa-check-circle text-primary me-3" />
                                        Nuestro Equipo
                                    </h5>
                                </div>
                                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.6s">
                                    <h5 className="mb-3">
                                        <i className="fa fa-check-circle text-primary me-3" />
                                        Abierto 24/7
                                    </h5>
                                    <h5 className="mb-3">
                                        <i className="fa fa-check-circle text-primary me-3" />
                                        Precios justos
                                    </h5>
                                </div>
                            </div> */}

                        </div>
                        <div className="col-lg-5" style={{ minHeight: 500 }}>
                            <div className="position-relative h-100">
                                <img
                                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                                    data-wow-delay="0.9s"
                                    src="img/portada.jpg"
                                    style={{ objectFit: "cover" }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* About End */}
            {/* Appointment Start */}
            {/* <div
                className="container-fluid bg-primary bg-appointment my-5 wow fadeInUp"
                data-wow-delay="0.1s"
            >
                <div className="container">
                    <div className="row gx-5">
                        <div className="col-lg-6 py-5">
                            <div className="py-5">
                                <h1 className="display-5 text-white mb-4">
                                    Tenemos premios de certificacion en clinicas dentales de confianza
                                </h1>
                                <p className="text-white mb-0">
                                    Eirmod sed tempor lorem ut dolores. Aliquyam sit sadipscing kasd
                                    ipsum. Dolor ea et dolore et at sea ea at dolor, justo ipsum duo
                                    rebum sea invidunt voluptua. Eos vero eos vero ea et dolore eirmod
                                    et. Dolores diam duo invidunt lorem. Elitr ut dolores magna sit.
                                    Sea dolore sanctus sed et. Takimata takimata sanctus sed.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div
                                className="appointment-form h-100 d-flex flex-column justify-content-center text-center p-5 wow zoomIn"
                                data-wow-delay="0.6s"
                            >
                                <h1 className="text-white mb-4">Saca un turno</h1>
                                <form>
                                    <div className="row g-3">
                                        <div className="col-12 col-sm-6">
                                            <select
                                                className="form-select bg-light border-0"
                                                style={{ height: 55 }}
                                            >
                                                <option value="">Seleccion un servicio</option>
                                                <option value={1}>Especialidad 1</option>
                                                <option value={2}>Especialidad 2</option>
                                                <option value={3}>Especialidad 3</option>
                                            </select>
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <select
                                                className="form-select bg-light border-0"
                                                style={{ height: 55 }}
                                            >
                                                <option value="">Escoge un profesional</option>
                                                <option value={1}>Doctor 1</option>
                                                <option value={2}>Doctor 2</option>
                                                <option value={3}>Doctor 3</option>
                                            </select>
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <input
                                                type="text"
                                                className="form-control bg-light border-0"
                                                placeholder="Tu correo electrónico"
                                                style={{ height: 55 }}
                                            />
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <input
                                                type="email"
                                                className="form-control bg-light border-0"
                                                placeholder="Tu correo electrónico"
                                                style={{ height: 55 }}
                                            />
                                        </div>
                                        <div className="col-12 col-sm-6">
                                        <div className="date" id="date1" data-target-input="nearest">
                                            <input
                                                type="text"
                                                className="form-control bg-light border-0 datetimepicker-input"
                                                placeholder="Appointment Date"
                                                data-target="#date1"
                                                data-toggle="datetimepicker"
                                                style={{ height: 55 }}
                                            />
                                        </div>
                                    </div>
                                        <div className="col-12 col-sm-6">
                                        <div className="time" id="time1" data-target-input="nearest">
                                            <input
                                                type="text"
                                                className="form-control bg-light border-0 datetimepicker-input"
                                                placeholder="Appointment Time"
                                                data-target="#time1"
                                                data-toggle="datetimepicker"
                                                style={{ height: 55 }}
                                            />
                                        </div>
                                    </div>
                                        <div className="col-12">
                                            <button className="btn btn-dark w-100 py-3" type="submit">
                                                Saca un turno
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* Appointment End */}
            {/* Service Start */}
            <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container">
                    <div className="row g-5 mb-5">
                        <div
                            className="col-lg-5 wow zoomIn"
                            data-wow-delay="0.3s"
                            style={{ minHeight: 400 }}
                        >
                            <div className="twentytwenty-container position-relative h-100 rounded overflow-hidden">
                                <img
                                    className="position-absolute w-100 h-100"
                                    src="img/before.jpg"
                                    style={{ objectFit: "cover" }}
                                />
                                <img
                                    className="position-absolute w-100 h-100"
                                    src="img/after.jpg"
                                    style={{ objectFit: "cover" }}
                                />
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="section-title mb-5">
                                <h5 className="position-relative d-inline-block text-primary text-uppercase">
                                    Nuestros servicios
                                </h5>
                                <h1 className="display-5 mb-0">
                                    Ofrecemos la mejor calidad en servicios dentales
                                </h1>
                            </div>
                            <div className="row g-5">
                                <div
                                    className="col-md-6 service-item wow zoomIn"
                                    data-wow-delay="0.6s"
                                >
                                    <div className="rounded-top overflow-hidden">
                                        <img className="img-fluid" src="img/odontologia.jpg" alt="" />
                                    </div>
                                    <div className="position-relative bg-light rounded-bottom text-center p-4">
                                        <h5 className="m-0">Odontología general</h5>
                                    </div>
                                </div>
                                <div
                                    className="col-md-6 service-item wow zoomIn"
                                    data-wow-delay="0.9s"
                                >
                                    <div className="rounded-top overflow-hidden">
                                        <img className="img-fluid" src="img/ortodoncia1.jpg" alt="" />
                                    </div>
                                    <div className="position-relative bg-light rounded-bottom text-center p-4">
                                        <h5 className="m-0">Ortodoncia</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row g-5 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="col-lg-12">
                            <div className="row g-5">
                                <div
                                    className="col-md-4 service-item wow zoomIn"
                                    data-wow-delay="0.3s"
                                >
                                    <div className="rounded-top overflow-hidden">
                                        <img className="img-fluid" src="img/odontopediatria.jpg" alt="" />
                                    </div>
                                    <div className="position-relative bg-light rounded-bottom text-center p-4">
                                        <h5 className="m-0">Odontopediatría</h5>
                                    </div>
                                </div>
                                <div
                                    className="col-md-4 service-item wow zoomIn"
                                    data-wow-delay="0.3s"
                                >
                                    <div className="rounded-top overflow-hidden">
                                        <img className="img-fluid" src="img/periodoncia.jpg" alt="" />
                                    </div>
                                    <div className="position-relative bg-light rounded-bottom text-center p-4">
                                        <h5 className="m-0">Periodoncia</h5>
                                    </div>
                                </div>
                                <div
                                    className="col-md-4 service-item wow zoomIn"
                                    data-wow-delay="0.3s"
                                >
                                    <div className="rounded-top overflow-hidden">
                                        <img className="img-fluid" src="img/endodoncia.jpg" alt="" />
                                    </div>
                                    <div className="position-relative bg-light rounded-bottom text-center p-4">
                                        <h5 className="m-0">Endodoncia</h5>
                                    </div>
                                </div>
                                <div
                                    className="col-md-4 service-item wow zoomIn"
                                    data-wow-delay="0.3s"
                                >
                                    <div className="rounded-top overflow-hidden">
                                        <img className="img-fluid" src="img/embarazada.jpg" alt="" />
                                    </div>
                                    <div className="position-relative bg-light rounded-bottom text-center p-4">
                                        <h5 className="m-0">Odontología para el emabarazo</h5>
                                    </div>
                                </div>
                                <div
                                    className="col-md-4 service-item wow zoomIn"
                                    data-wow-delay="0.6s"
                                >
                                    <div className="rounded-top overflow-hidden">
                                        <img className="img-fluid" src="img/blanqueamiento.jpg" alt="" />
                                    </div>
                                    <div className="position-relative bg-light rounded-bottom text-center p-4">
                                        <h5 className="m-0">Blanqueamiento dental</h5>
                                    </div>
                                </div>
                                <div
                                    className="col-md-4 service-item wow zoomIn"
                                    data-wow-delay="0.6s"
                                >
                                    <div className="rounded-top overflow-hidden">
                                        <img className="img-fluid" src="img/implantes.jpg" alt="" />
                                    </div>
                                    <div className="position-relative bg-light rounded-bottom text-center p-4">
                                        <h5 className="m-0">Implantes y prótesis</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 service-item wow zoomIn" data-wow-delay="0.9s">
                            <div className="position-relative bg-primary rounded h-70 d-flex flex-column align-items-center justify-content-center text-center p-4">
                                <h3 className="text-white mb-3">Aún si ya eres Paciente de nosotros <br /> Registrate y saca un turno</h3>
                                <p className="text-white mb-3">
                                    En el Consultorio Odín, ofrecemos una amplia gama de servicios dentales, desde limpiezas y exámenes de rutina hasta tratamientos de ortodoncia, implantes dentales y estética dental. Nuestro equipo utiliza tecnología avanzada y técnicas actualizadas para garantizar resultados de alta calidad y satisfacción del paciente.

                                </p>
                                {/* <h2 className="text-white mb-0">
                                    <i className="fa fa-phone-alt me-2" />
                                    5491125816420 &nbsp;
                                    <i className="fab fa-whatsapp"></i>
                                </h2> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Service End */}
            {/* Offer Start */}
            {/* <div
                className="container-fluid bg-offer my-5 py-5 wow fadeInUp"
                data-wow-delay="0.1s"
            >
                <div className="container py-5">
                    <div className="row justify-content-center">
                        <div className="col-lg-7 wow zoomIn" data-wow-delay="0.6s">
                            <div className="offer-text text-center rounded p-5">
                                <h1 className="display-5 text-white">
                                    Ahorra 30% en tu primer revisión
                                </h1>
                                <p className="text-white mb-4">
                                    Eirmod sed tempor lorem ut dolores sit kasd ipsum. Dolor ea et
                                    dolore et at sea ea at dolor justo ipsum duo rebum sea. Eos vero
                                    eos vero ea et dolore eirmod diam duo lorem magna sit dolore sed
                                    et.
                                </p>
                                <a href="appointment.html" className="btn btn-dark py-3 px-5 me-3">
                                    Turnos
                                </a>
                                <a href="" className="btn btn-light py-3 px-5">
                                    Conoce mas
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* Offer End */}
            {/* Pricing Start */}
            {/* <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-5">
                            <div className="section-title mb-4">
                                <h5 className="position-relative d-inline-block text-primary text-uppercase">
                                    Obras sociales
                                </h5>
                                <h1 className="display-5 mb-0">
                                    Ofrecenos precios justos por los tratamientos
                                </h1>
                            </div>
                            <p className="mb-4">
                                Tempor erat elitr rebum at clita. Diam dolor diam ipsum et tempor
                                sit. Aliqu diam amet diam et eos labore. Clita erat ipsum et lorem
                                et sit, sed stet no labore lorem sit. Sanctus clita duo justo eirmod
                                magna dolore erat amet
                            </p>
                            <h5
                                className="text-uppercase text-primary wow fadeInUp"
                                data-wow-delay="0.3s"
                            >
                                Llamanos!
                            </h5>
                            <h1 className="wow fadeInUp" data-wow-delay="0.6s">
                                <i className="fa fa-phone-alt me-2" />
                                5491125816420 &nbsp;
                                <i className="fab fa-whatsapp"></i>
                            </h1>
                        </div>
                        <div className="col-lg-7">
                            <div
                                className="owl-carousel price-carousel wow zoomIn"
                                data-wow-delay="0.9s"
                            >
                                <div className="price-item pb-4">
                                    <div className="position-relative">
                                        <img
                                            className="img-fluid rounded-top"
                                            src="img/price-1.jpg"
                                            alt=""
                                        />
                                        <div
                                            className="d-flex align-items-center justify-content-center bg-light rounded pt-2 px-3 position-absolute top-100 start-50 translate-middle"
                                            style={{ zIndex: 2 }}
                                        >
                                            <h2 className="text-primary m-0">$35</h2>
                                        </div>
                                    </div>
                                    <div className="position-relative text-center bg-light border-bottom border-primary py-5 p-4">
                                        <h4>Blanqueamiento dental</h4>
                                        <hr className="text-primary w-50 mx-auto mt-0" />
                                        <div className="d-flex justify-content-between mb-3">
                                            <span>Equipo moderno</span>
                                            <i className="fa fa-check text-primary pt-1" />
                                        </div>
                                        <div className="d-flex justify-content-between mb-3">
                                            <span>Dentistas profesionales</span>
                                            <i className="fa fa-check text-primary pt-1" />
                                        </div>
                                        <div className="d-flex justify-content-between mb-2">
                                            <span>24/7 Atencion telefonica</span>
                                            <i className="fa fa-check text-primary pt-1" />
                                        </div>
                                        <a
                                            href="appointment.html"
                                            className="btn btn-primary py-2 px-4 position-absolute top-100 start-50 translate-middle"
                                        >
                                            Turnos
                                        </a>
                                    </div>
                                </div>
                                <div className="price-item pb-4">
                                    <div className="position-relative">
                                        <img
                                            className="img-fluid rounded-top"
                                            src="img/price-2.jpg"
                                            alt=""
                                        />
                                        <div
                                            className="d-flex align-items-center justify-content-center bg-light rounded pt-2 px-3 position-absolute top-100 start-50 translate-middle"
                                            style={{ zIndex: 2 }}
                                        >
                                            <h2 className="text-primary m-0">$49</h2>
                                        </div>
                                    </div>
                                    <div className="position-relative text-center bg-light border-bottom border-primary py-5 p-4">
                                        <h4>Implantes dentales</h4>
                                        <hr className="text-primary w-50 mx-auto mt-0" />
                                        <div className="d-flex justify-content-between mb-3">
                                            <span>Equipamiento moderno</span>
                                            <i className="fa fa-check text-primary pt-1" />
                                        </div>
                                        <div className="d-flex justify-content-between mb-3">
                                            <span>Dentistas profesionales</span>
                                            <i className="fa fa-check text-primary pt-1" />
                                        </div>
                                        <div className="d-flex justify-content-between mb-2">
                                            <span>24/7 Atencion telefonica</span>
                                            <i className="fa fa-check text-primary pt-1" />
                                        </div>
                                        <a
                                            href="appointment.html"
                                            className="btn btn-primary py-2 px-4 position-absolute top-100 start-50 translate-middle"
                                        >
                                            Saca un turno
                                        </a>
                                    </div>
                                </div>
                                <div className="price-item pb-4">
                                    <div className="position-relative">
                                        <img
                                            className="img-fluid rounded-top"
                                            src="img/price-3.jpg"
                                            alt=""
                                        />
                                        <div
                                            className="d-flex align-items-center justify-content-center bg-light rounded pt-2 px-3 position-absolute top-100 start-50 translate-middle"
                                            style={{ zIndex: 2 }}
                                        >
                                            <h2 className="text-primary m-0">$99</h2>
                                        </div>
                                    </div>
                                    <div className="position-relative text-center bg-light border-bottom border-primary py-5 p-4">
                                        <h4>Canal principal</h4>
                                        <hr className="text-primary w-50 mx-auto mt-0" />
                                        <div className="d-flex justify-content-between mb-3">
                                            <span>Equipos modernos</span>
                                            <i className="fa fa-check text-primary pt-1" />
                                        </div>
                                        <div className="d-flex justify-content-between mb-3">
                                            <span>Dentistas profesionales</span>
                                            <i className="fa fa-check text-primary pt-1" />
                                        </div>
                                        <div className="d-flex justify-content-between mb-2">
                                            <span>24/7 Atencion telefonica</span>
                                            <i className="fa fa-check text-primary pt-1" />
                                        </div>
                                        <a
                                            href="appointment.html"
                                            className="btn btn-primary py-2 px-4 position-absolute top-100 start-50 translate-middle"
                                        >
                                            Turnos
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* Pricing End */}
            {/* Testimonial Start */}
            {/* <div
                className="container-fluid bg-primary bg-testimonial py-5 my-5 wow fadeInUp"
                data-wow-delay="0.1s"
            >
                <div className="container py-5">
                    <div className="row justify-content-center">
                        <div className="col-lg-7">
                            <div
                                className="owl-carousel testimonial-carousel rounded p-5 wow zoomIn"
                                data-wow-delay="0.6s"
                            >
                                <div className="testimonial-item text-center text-white">
                                    <img
                                        className="img-fluid mx-auto rounded mb-4"
                                        src="img/testimonial-1.jpg"
                                        alt=""
                                    />
                                    <p className="fs-5">
                                        Dolores sed duo clita justo dolor et stet lorem kasd dolore
                                        lorem ipsum. At lorem lorem magna ut et, nonumy labore diam
                                        erat. Erat dolor rebum sit ipsum.
                                    </p>
                                    <hr className="mx-auto w-25" />
                                    <h4 className="text-white mb-0">Nombre de cliente</h4>
                                </div>
                                <div className="testimonial-item text-center text-white">
                                    <img
                                        className="img-fluid mx-auto rounded mb-4"
                                        src="img/testimonial-2.jpg"
                                        alt=""
                                    />
                                    <p className="fs-5">
                                        Dolores sed duo clita justo dolor et stet lorem kasd dolore
                                        lorem ipsum. At lorem lorem magna ut et, nonumy labore diam
                                        erat. Erat dolor rebum sit ipsum.
                                    </p>
                                    <hr className="mx-auto w-25" />
                                    <h4 className="text-white mb-0">Nombre de cliente</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* Testimonial End */}
            {/* Team Start */}
            {/* <div className="container-fluid py-5">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.1s">
                            <div className="section-title bg-light rounded h-100 p-5">
                                <h5 className="position-relative d-inline-block text-primary text-uppercase">
                                    Nuestros dentistas
                                </h5>
                                <h1 className="display-6 mb-4">
                                    Conoce a nuestros &amp; Experimentados Profesionales
                                </h1>
                                <a href="appointment.html" className="btn btn-primary py-3 px-5">
                                    Turnos
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
                            <div className="team-item">
                                <div
                                    className="position-relative rounded-top"
                                    style={{ zIndex: 1 }}
                                >
                                    <img
                                        className="img-fluid rounded-top w-100"
                                        src="img/team-1.jpg"
                                        alt=""
                                    />
                                    <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
                                        <a className="btn btn-primary btn-square m-1" href="#">
                                            <i className="fab fa-twitter fw-normal" />
                                        </a>
                                        <a className="btn btn-primary btn-square m-1" href="#">
                                            <i className="fab fa-facebook-f fw-normal" />
                                        </a>
                                        <a className="btn btn-primary btn-square m-1" href="#">
                                            <i className="fab fa-linkedin-in fw-normal" />
                                        </a>
                                        <a className="btn btn-primary btn-square m-1" href="#">
                                            <i className="fab fa-instagram fw-normal" />
                                        </a>
                                    </div>
                                </div>
                                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                                    <h4 className="mb-2">Dr. John Doe</h4>
                                    <p className="text-primary mb-0">Cirugia de implantes</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
                            <div className="team-item">
                                <div
                                    className="position-relative rounded-top"
                                    style={{ zIndex: 1 }}
                                >
                                    <img
                                        className="img-fluid rounded-top w-100"
                                        src="img/team-2.jpg"
                                        alt=""
                                    />
                                    <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
                                        <a className="btn btn-primary btn-square m-1" href="#">
                                            <i className="fab fa-twitter fw-normal" />
                                        </a>
                                        <a className="btn btn-primary btn-square m-1" href="#">
                                            <i className="fab fa-facebook-f fw-normal" />
                                        </a>
                                        <a className="btn btn-primary btn-square m-1" href="#">
                                            <i className="fab fa-linkedin-in fw-normal" />
                                        </a>
                                        <a className="btn btn-primary btn-square m-1" href="#">
                                            <i className="fab fa-instagram fw-normal" />
                                        </a>
                                    </div>
                                </div>
                                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                                    <h4 className="mb-2">Dr. John Doe</h4>
                                    <p className="text-primary mb-0">Cirugia de implantes</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.1s">
                            <div className="team-item">
                                <div
                                    className="position-relative rounded-top"
                                    style={{ zIndex: 1 }}
                                >
                                    <img
                                        className="img-fluid rounded-top w-100"
                                        src="img/team-3.jpg"
                                        alt=""
                                    />
                                    <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
                                        <a className="btn btn-primary btn-square m-1" href="#">
                                            <i className="fab fa-twitter fw-normal" />
                                        </a>
                                        <a className="btn btn-primary btn-square m-1" href="#">
                                            <i className="fab fa-facebook-f fw-normal" />
                                        </a>
                                        <a className="btn btn-primary btn-square m-1" href="#">
                                            <i className="fab fa-linkedin-in fw-normal" />
                                        </a>
                                        <a className="btn btn-primary btn-square m-1" href="#">
                                            <i className="fab fa-instagram fw-normal" />
                                        </a>
                                    </div>
                                </div>
                                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                                    <h4 className="mb-2">Dr. John Doe</h4>
                                    <p className="text-primary mb-0">Cirugia de implantes</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
                            <div className="team-item">
                                <div
                                    className="position-relative rounded-top"
                                    style={{ zIndex: 1 }}
                                >
                                    <img
                                        className="img-fluid rounded-top w-100"
                                        src="img/team-4.jpg"
                                        alt=""
                                    />
                                    <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
                                        <a className="btn btn-primary btn-square m-1" href="#">
                                            <i className="fab fa-twitter fw-normal" />
                                        </a>
                                        <a className="btn btn-primary btn-square m-1" href="#">
                                            <i className="fab fa-facebook-f fw-normal" />
                                        </a>
                                        <a className="btn btn-primary btn-square m-1" href="#">
                                            <i className="fab fa-linkedin-in fw-normal" />
                                        </a>
                                        <a className="btn btn-primary btn-square m-1" href="#">
                                            <i className="fab fa-instagram fw-normal" />
                                        </a>
                                    </div>
                                </div>
                                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                                    <h4 className="mb-2">Dr. John Doe</h4>
                                    <p className="text-primary mb-0">Cirugia de implantes</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
                            <div className="team-item">
                                <div
                                    className="position-relative rounded-top"
                                    style={{ zIndex: 1 }}
                                >
                                    <img
                                        className="img-fluid rounded-top w-100"
                                        src="img/team-5.jpg"
                                        alt=""
                                    />
                                    <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
                                        <a className="btn btn-primary btn-square m-1" href="#">
                                            <i className="fab fa-twitter fw-normal" />
                                        </a>
                                        <a className="btn btn-primary btn-square m-1" href="#">
                                            <i className="fab fa-facebook-f fw-normal" />
                                        </a>
                                        <a className="btn btn-primary btn-square m-1" href="#">
                                            <i className="fab fa-linkedin-in fw-normal" />
                                        </a>
                                        <a className="btn btn-primary btn-square m-1" href="#">
                                            <i className="fab fa-instagram fw-normal" />
                                        </a>
                                    </div>
                                </div>
                                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                                    <h4 className="mb-2">Dr. John Doe</h4>
                                    <p className="text-primary mb-0">Cirugia de implantes</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* Team End */}
            {/* Newsletter Start */}
            {/* <div
                className="container-fluid position-relative pt-5 wow fadeInUp"
                data-wow-delay="0.1s"
                style={{ zIndex: 1 }}
            >
                <div className="container">
                    <div className="bg-primary p-5">
                        <form className="mx-auto" style={{ maxWidth: 600 }}>
                            <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control border-white p-3"
                                    placeholder="Tu correo electrónico"
                                />
                                <button className="btn btn-dark px-4">Registrate</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div> */}
            {/* Newsletter End */}
            <Footer />
            {/* Back to Top */}
            <a
                href="#"
                className="btn btn-lg btn-primary btn-lg-square rounded back-to-top"
            >
                <i className="bi bi-arrow-up" />
            </a>
        </>
    )
}

export default LandingPage