/* eslint-disable */
import React, { useEffect, useState, useContext } from "react";
import {
  Dropdown,
  DropdownButton,
  Form,
  Button,
  Modal,
  Col,
} from "react-bootstrap";
import { AuthContext } from "../../contexts/AuthContext";
import axios from "axios";
import Swal from "sweetalert2";
import "../../index.css";
import dayjs from "dayjs";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import getDay from "date-fns/getDay";
import es from "date-fns/locale/es";
registerLocale("es", es);

function EditSchedule(props) {
  const { user1, isAuth } = useContext(AuthContext);
  const [schedule, setSchedule] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [date, setDate] = useState("Fecha");
  const [time, setTime] = useState("Hora");
  const [note, setNote] = useState(props.notee);
  const [doctor, setDoctor] = useState(user1.id);
  const [doctorName, setDoctorName] = useState("Escoge");
  const [doctorLname, setDoctorLname] = useState("Doctor");
  const [usrdates, setUsrdates] = useState([]);
  // eslint-disable-next-line
  const [usdat, setUsdat] = useState([]);
  const [fecha, setFecha] = useState("");
  const [data, setData] = useState([]);
  const [es, setes] = useState(["07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00"]);
  const [startDate, setStartDate] = useState(null);
  const [borbot, setBorbot] = useState([]);
  const [apa, setApa] = useState("btn btn-info  apagado");
  const [sinHoras, setSinHoras] = useState(false);
  const titleedbot = `${doctorName} ${doctorLname}`;
  const excludeColumns = ["_id", "is_active", "createdAt", "updatedAt"]; // excluye datos del arreglo del filtro
  const SCHDOCGET = `${process.env.REACT_APP_API}schedulesbydoctor/${user1.id}/${doctor}`;
  const SCHUSRGET = `${process.env.REACT_APP_API}schedulesbyuser/${user1.id}/${user1.id}`;
  const DOCGET = `${process.env.REACT_APP_API}doctors/${user1.id}`;
  const SCHPATCH = `${process.env.REACT_APP_API}schedule/${user1.id}/${props.id}`;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [blockHours, setBlockHours] = useState([])
  const [daysOff, setDaysOff] = useState([])
  let tarde = dayjs().$d >= dayjs(`${props.datee.split("T")[0]} ${props.timee}`).subtract(1, 'day').$d
  let resultadoHoras = es.filter(item => !blockHours.includes(item)).sort();
  let citas = []
  let resultado2 = {}
  const highlightWithRanges = [
    { "react-datepicker__day--highlighted-custom-1": [], },
    { "react-datepicker__day--highlighted-custom-2": [], },
  ];
  let meses = [ "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]

  useEffect(() => {
    axios
      .get(SCHUSRGET, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((data) => setUsrdates(data.data))
      .catch((err) => console.log(err));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    axios
      .get(DOCGET, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((data) => {
        let temp = [];
        let num = 0;
        for (let i in data.data) {
          if (data.data[i].obraSocialAtendida.includes(user1.obraSocial)) {
            temp.push(data.data[i]);
          }
          num++;
          if (num == data.data.length) {
            setDoctors(temp);
          }
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line
  }, [usrdates]);

  useEffect(() => {
    axios
      .get(SCHDOCGET, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((data) => setSchedule(data.data))
      .catch((err) => console.log(err));
    // eslint-disable-next-line
  }, [doctor]);
  
  useEffect(() => {
    verify();
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    udates();
    // eslint-disable-next-line
  }, [usrdates]);

  function color() {
    for (let i in schedule) {
      citas.push(schedule[i].date.split('T')[0])
    }
    citas.forEach(el => { resultado2[el] = resultado2[el] + 1 || 1 })

    Object.keys(resultado2).forEach(key => {
      if (resultado2[key] == resultadoHoras.length) {
        usdat.push(new Date(dayjs(key)))
      }
      if (resultado2[key] > resultadoHoras.length * .5 && resultado2[key] < resultadoHoras.length * .8) {
        highlightWithRanges[1]["react-datepicker__day--highlighted-custom-2"].push(new Date(dayjs(key)))
      }
      if (resultado2[key] > resultadoHoras.length * .8) {
        highlightWithRanges[0]["react-datepicker__day--highlighted-custom-1"].push(new Date(dayjs(key)))
      }
    });

  }
  color()

  const diaSeleccionado = (selectedDay) => {
    setSinHoras(false);
    setes(["07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00"]);
    setBorbot([""]);
    setApa("btn btn-info ");
    setTime("Hora");
    setStartDate(selectedDay);
    setDate(`${dayjs(selectedDay).format("YYYY-MM-DD")}T`);
    filterData(`${dayjs(selectedDay).format("YYYY-MM-DD")}T`)
    setFecha(`${dayjs(selectedDay).format("DD/MM/YYYY")}T`)
  };

  const escogeHora = (horario) => {
    setTime(horario);
    let x = date.slice(0, 11);
    let y = fecha.slice(0, 11);
    setDate(`${x}${horario}`);
    setFecha(`${y}  ${horario}`);
  };

  const filterData = (value) => {
    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === "") setData(schedule);
    else {
      const filteredData = schedule.filter((item) => {
        return Object.keys(item).some((key) =>
          excludeColumns.includes(key)
            ? false
            : String(item[key]).toLowerCase().includes(lowercasedValue)
        );
      });
      setData(filteredData);
    }
  };

  const verify = () => {
    data.map((info) => borbot.push(info.time));
    const disponibles = es.filter((item) => !borbot.includes(item));

    if (disponibles.length === 0) {
      setes(disponibles);
      setSinHoras(true);
    } else {
      setes(disponibles);
    }
  };

  const udates = () => {
    let usdates = [];
    usrdates.map((info) => usdates.push(info.date));
    let year = usdates.map((v) => parseInt(v.slice(0, 4)));
    let month = usdates.map((v) => parseInt(v.slice(5, 7)));
    let day = usdates.map((v) => parseInt(v.slice(8, 10)));
    let datos = [];

    for (var i = 0; i < year.length; i++) {
      datos.push(new Date(`${year[i]},${month[i]},${day[i]}`));
      setUsdat(datos);
    }
  };

  const editDate = () => {
    if (
      doctor === user1.id ||
      date === "Fecha" ||
      time === "Hora" ||
      date === "Fecha07:00" ||
      date === "Fecha08:00" ||
      date === "Fecha09:00" ||
      date === "Fecha10:00" ||
      date === "Fecha11:00" ||
      date === "Fecha12:00" ||
      date === "Fecha13:00" ||
      date === "Fecha14:00" ||
      date === "Fecha15:00" ||
      date === "Fecha16:00" ||
      date === "Fecha17:00" ||
      date === "Fecha18:00" ||
      date === "Fecha19:00" ||
      date === "Fecha20:00"
    ) {
      return Swal.fire({
        allowEscapeKey: true,
        icon: "info",
        title: "Informacion incompleta",
        text: "Escoge todos los campos",
        confirmButtonText: `Ok`,
        timerProgressBar: true,
      });
    }
    Swal.fire({
      title: `Tu turno sera reprogramado con el Dr. ${doctorName} ${doctorLname}, para el ${date.slice(
        0,
        10
      )} ${time} hrs.`,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      allowEscapeKey: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirmar Turno",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .patch(
            SCHPATCH,
            {
              date,
              time,
              note,
              profesional: doctor,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then(() => {
            Swal.fire({
              icon: "success",
              title: "Se edito con exito",
              confirmButtonText: `Ok`,
              timer: 1000,
              timerProgressBar: true,
              allowEscapeKey: true,
            }).then(() => {
              window.location.reload();
            });
          })
          .catch((error) => {
            let message = error.response.data.message;
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Lo sentimos esta acción no se pudo completar " + message,
              allowEscapeKey: true,
            });
          });
      }
    });
  };

  const isWeekday = (date) => {
    const day = getDay(date);
    return !daysOff.some((dayOff) => {
      if (day == dayOff) {
        return true;
      }
      return false;
    });
  };

  /*     
        ESTE CODIGOS SIRVE PARA HACER COMPARACIONES DE HORARIOS DESDE MONOG DB CON CAMBIO DE HORARIO LOCAL

        ----------------------------------
        LO QUE ESTAMOS HACIENDO ACA ES UNA VEZ OBTENIDO LA FECHA LA CORTABAMOS Y AGRAGABAMOS LA HORA ELEGIDA 
        POR EL USUARIO Y  LO SIGUIENE FUE AGREGAR COMO STRING LO RESTANTE PARA PODER IGUALARLO CON LA FECHA DE 
        LA API

        let x = date.slice(0, 11)
        setDate2(`${x}${horario}:00.000Z`)
       ------------------------------------

        let citaApi = new Date(info.date).valueOf()
        
        let pick = new Date(date2).valueOf()

        let horario = 1000 * 60 * 60 * 6; // aqui sumo 6 horas para México

        let escogida = pick+horario // aqui suma las horas de mexico

        console.log(citaApi + "cita de api");
        console.log(escogida + "escogida para comparar")       
  */

  return (
    <>
      {isAuth ? (
        user1.role === "user" || user1.role === "admin" ? (
          <div>
            <Button variant="info" onClick={handleShow}>
              <i className="far fa-edit"></i>
            </Button>
            <Modal show={show} size="sm" onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Edicion de Turno</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="actual">
                  <h6 className="nomargin">Turno actual:</h6>
                  <p className="nomargin">
                    Dr. {props.doctorefn} {props.doctoreln}
                  </p>
                  <p className="nomargin">{`Fecha: ${dayjs(props.datee).$D} de ${meses[dayjs(props.datee).$M]} de ${dayjs(props.datee).$y}`}</p>
                  <p className="nomargin">{props.timee} hrs.</p>
                </div>

                <Form>
                  <Col>
                    <Form.Group>
                      <DropdownButton
                        variant="outline-info"
                        id="dropdown-basic-button"
                        title={titleedbot}
                      >
                        {doctors.map((user, i) => (
                          <Dropdown.Item
                            onClick={() => {
                              setDoctor(user._id);
                              setDoctorName(user.nombre);
                              setDoctorLname(user.apellido);
                              setDate("Fecha");
                              setTime("Hora");
                              setDate(null);
                              setStartDate(null)
                              setDaysOff(user.diasOff)
                              setBlockHours(user.horasOff)
                            }}
                            key={i}
                          >
                            <h4 className="alineacion">
                              {user.nombre} {user.apellido}<br />{user.especialidad}
                            </h4>
                          </Dropdown.Item>
                        ))}
                      </DropdownButton>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <DatePicker
                        calendarStartDay={0}
                        locale="es"
                        todayButton="Hoy"
                        highlightDates={highlightWithRanges}
                        className="picker"
                        placeholderText="Selecciona la Fecha"
                        selected={startDate}
                        dateFormat="dd/MM/yyyy"
                        includeDateIntervals={[
                          { start: new Date(), end: new Date(dayjs().add(90, 'day')) },]}
                        // excludeDates={usdat}
                        filterDate={isWeekday}
                        onChange={(date) => diaSeleccionado(date)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <DropdownButton
                        variant="outline-info"
                        id="dropdown-basic-button"
                        title={time}
                      >
                        {sinHoras ? (
                          <>
                            <h6 className="CitaSeleccionada sinhoras">
                              Ups! no hay horas disponibles.<br></br>Escoge otro
                              dia por favor
                            </h6>
                          </>
                        ) : (
                          <>
                            {resultadoHoras.map((hora, i) => (
                              <Dropdown.Item
                                onClick={() => escogeHora(hora, i)}
                                key={i}
                                className={apa}
                              >
                                <h4 className="alineacion">{hora}</h4>
                              </Dropdown.Item>
                            ))}
                          </>
                        )}
                      </DropdownButton>
                    </Form.Group>
                  </Col>
                  <Form.Group>
                    <textarea
                      className="note"
                      placeholder={note}
                      rows="3"
                      onChange={(e) => {
                        setNote(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  type="submit"
                  onClick={() => {
                    editDate();
                  }}
                  className="btn btn-info"
                >
                  Editar
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        ) : undefined
      ) : undefined}
    </>
  );
}

export default EditSchedule;
